import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {PageComponent} from '@synisys/idm-de-service-client-js';

@Component({
             moduleId   : module.id + '',
             selector   : 'lock-error-page',
             templateUrl: 'lock-error-page.component.html',
           })
export class LockErrorPageComponent extends PageComponent implements OnInit {

  userName: string;

  constructor(private route: ActivatedRoute) {
    super();
  }

  public ngOnInit() {
    this.userName = this.route.snapshot.paramMap.get('userName');
  }

  public onOkClicked(): void {
    window.history.back();
  }

}
