import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

import './conformation-dialog.component.scss';
import {TokenInfoService} from '@synisys/idm-authentication-client-js';
import {AbstractDestructionSubject} from '../../../component/abstract-destruction-subject';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: 'conformation-dialog.component.html',
})
export class ConformationDialogComponent extends AbstractDestructionSubject
    implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ConformationDialogComponent>,
        private tokenInfoService: TokenInfoService
    ) {
        super();
    }

    public onConfirmClick(): void {
        this.dialogRef.close(true);
    }

    public onCancelClick(): void {
        this.dialogRef.close(false);
    }

    public ngOnInit(): void {
        this.tokenInfoService.tokenValidityObservable
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(isValid => {
                if (!isValid) {
                    this.dialogRef.close(false);
                }
            }, console.log);
    }
}
