/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';

export abstract class LockGuard implements CanActivate {

  public abstract canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;
  public  checkForLock(systemName: string, formName: string, instanceId: number, formId: number): Observable<boolean> {
    return Observable.of(true);
  }
}
