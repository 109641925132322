import {Injectable} from '@angular/core';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {DraftPageService, PageDto, PageStructureDto} from '@synisys/idm-page-service-client-js';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class JsonBasedPageService extends DraftPageService {

  protected kbJSON: ReplaySubject<object> = new ReplaySubject<object>(1);
  protected propsJSON: ReplaySubject<object> = new ReplaySubject<object>(1);
  private readonly kbJSONKey: string = 'dynamic-forms-kb-json-path';
  private readonly propsJSONKey: string = 'dynamic-forms-properties-json-path';

  constructor(protected http: HttpClient,
              protected applicationPropertiesService: ApplicationPropertiesService) {
    super();
    this.init();
  }

  public getPageStructure(pageId: number, pageName?: string): Promise<PageStructureDto> {
    return Observable.combineLatest<object, object>(this.kbJSON, this.propsJSON)
      .first()
      .map((data: object[]) => {
        const kbJson: object = {};
        const propJson: object = {};
        kbJson[pageName] = data[0][pageName];
        propJson[pageName] = data[1][pageName];
        return this.createPageModel(kbJson, propJson);
      }).toPromise();
  }

  public addPage(page: PageDto): Promise<object> {
    throw Error('Not Implemented Yet');
  }

  public deletePage(pageId: number): Promise<object> {
    throw Error('Not Implemented Yet');
  }

  public getPage(pageId?: number): Promise<PageDto> {
    throw Error('Not Implemented Yet');
  }

  public updatePage(page: PageDto): Promise<object> {
    throw Error('Not Implemented Yet');
  }

  public updatePageStructure(pageId: number, pageStructure: PageStructureDto): Promise<object> {
    throw Error('Not Implemented Yet');
  }

  public getPageHierarchy(pageId: number): Promise<PageDto[]> {
    throw Error('Not Implemented Yet');
  }

  public getPagePath(pageId: number): Observable<string> {
    throw Error('Not Implemented Yet');
  }

  public getAllPages(): Promise<PageDto[]> {
    throw Error('Not Implemented Yet');
  }

  public updatePages(pages: PageDto[]): Promise<any> {
    throw Error('Not Implemented Yet');
  }

  private init(): void {
    this.applicationPropertiesService
      .getProperty(this.kbJSONKey)
      .then((path: string) =>
        this.http.get<object>(path).subscribe(
          (data: object) => this.kbJSON.next(data),
          (error: object) => console.error(error)),
      );

    this.applicationPropertiesService
      .getProperty(this.propsJSONKey)
      .then((path: string) =>
        this.http.get<object>(path).subscribe(
          (data: object) => this.propsJSON.next(data),
          (error: object) => console.error(error)),
      );
  }

  private createPageModel(kbJSON: object, propsJSON: object): PageStructureDto {
    const obj: string = JSON.stringify({'layout': JSON.stringify(kbJSON), 'property': JSON.stringify(propsJSON)});
    return new PageStructureDto(obj);
  }

}
