import {MultilingualStringBuilder} from '@synisys/idm-crosscutting-concepts-frontend';
import {MetaFieldType} from '@synisys/idm-kb-service-client-js';

// tslint:disable-next-line:no-any
export function defaultValueProvider(fieldType: MetaFieldType): any {
    switch (fieldType) {
        case MetaFieldType.ACCOUNTING:
        case MetaFieldType.BIG_DECIMAL:
        case MetaFieldType.BOOLEAN:
        case MetaFieldType.CLASSIFIER:
        case MetaFieldType.DATE:
        case MetaFieldType.DATE_TIME:
        case MetaFieldType.DECIMAL:
        case MetaFieldType.DOCUMENT:
        case MetaFieldType.INTEGER:
        case MetaFieldType.INTEGER_IDENTITY:
        case MetaFieldType.INTEGER_INSTANCE:
        case MetaFieldType.LOCAL_DATE:
        case MetaFieldType.LOCAL_DATE_TIME:
        case MetaFieldType.MAIN_ENTITY:
        case MetaFieldType.MONEY:
        case MetaFieldType.MULTILINESTRING:
        case MetaFieldType.PARENT:
        case MetaFieldType.STRING:
        case MetaFieldType.USER:
        case MetaFieldType.WORKFLOW_STATE:
            // tslint:disable-next-line:no-null-keyword
            return null;
        case MetaFieldType.SUB_ENTITY:
        case MetaFieldType.MULTI_SELECT:
            return [];
        case MetaFieldType.MULTILINGUAL_STRING:
            return new MultilingualStringBuilder().build();
    }
}

// tslint:disable-next-line:no-any
function cloneValidationMap(toClone: Map<string, any>) {
    const result = new Map();
    toClone.forEach((value, key) => {
        result.set(key, value);
    });

    return result;
}
