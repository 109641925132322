import {Injectable} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DeService,
    getUniqueIdForSubEntity,
    MainEntity,
    SubEntity,
} from '@synisys/idm-de-service-client-js';
import {
    DocumentInfo,
    DocumentService,
    FileType,
} from '@synisys/idm-document-service-client-js';
import {Observable} from 'rxjs/Observable';
import {DynamicFormInterface} from './dynamic-from.interface';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    FieldArg,
    LayoutAction,
    StateArg,
} from '../layout-actions';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {DynamicFormComponent} from '../../component/form';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Classifier} from '@synisys/idm-classifier-service-client-js';

@Injectable()
export class DynamicFormDocumentsService {
    constructor(
        private deService: DeService,
        private kbService: KbService,
        private documentService: DocumentService
    ) {}

    /**
     * Downloads the document
     * @param documentId             document id to download
     * @param documentRootField      document field, if the document is not nested,
     *                                                otherwise first level field (from MainEntity)
     * @param categoryName           category of MainEntity
     * @param entity                 mainEntity
     * @param form                   main form
     * @param fileType
     */
    public onDownload(
        documentId: number,
        documentRootField: string,
        categoryName: string,
        entity: Entity,
        form: DynamicFormInterface,
        fileType: FileType = FileType.ORIGINAL
    ): void {
        if (form.temporaryDocuments.has(documentId)) {
            // check if document is confirmed
            this.documentService.downloadTempDocument(documentId);
            return;
        }
        this.deService
            .getDocumentToken(
                categoryName,
                entity.getInstanceId(),
                documentRootField
            )
            .mergeMap((token: string) =>
                this.documentService.getDownloadUrl(documentId, token)
            )
            .subscribe(
                (url: string) =>
                    this.documentService.downloadDocument(url, fileType),
                console.error
            );
    }

    public downloadByUrl(
        url: string,
        fileType: FileType = FileType.ORIGINAL
    ): void {
        this.documentService.downloadDocument(url, fileType);
    }

    /**
     * returns Observable of document's URL
     * @param documentId             document id to download
     * @param documentRootField      document field, if the document is not nested,
     *                                               first level field (from MainEntity) otherwise
     * @param categoryName           category of MainEntity
     * @param entity                 mainEntity
     * @param form                   main form
     */
    public getDownloadUrl(
        documentId: number,
        documentRootField: string,
        categoryName: string,
        entity: Entity,
        form: DynamicFormInterface
    ): Observable<string> {
        if (form.temporaryDocuments.has(documentId)) {
            return this.documentService.getTempDocumentUrl(documentId);
        }
        return this.deService
            .getDocumentToken(
                categoryName,
                entity.getInstanceId(),
                documentRootField
            )
            .mergeMap((token: string) =>
                this.documentService.getDownloadUrl(documentId, token)
            );
    }

    /**
     * removes document by documentId from the temporary documents (if exist). if the parent entity and the field of
     *                                                                this document are given then removes from entity
     * @param documentId             document id to remove
     * @param form                   form interface
     * @param documentEntity         parent entity
     * @param documentEntityField    document field
     */
    public removeDocument(
        documentId: number,
        form: DynamicFormInterface,
        documentEntity?: Entity,
        documentEntityField?: string
    ): void {
        if (form.temporaryDocuments.has(documentId)) {
            form.temporaryDocuments.delete(documentId);
        }
        if (
            documentEntity &&
            documentEntity.getProperty<number>(documentEntityField).value ===
                documentId
        ) {
            documentEntity.getProperty<number>(
                documentEntityField
            ).value = null;
        }
    }

    /**
     * sets value to given entity, and saves for confirming
     * @param documentId             documentId
     * @param documentEntity         document's parent entity
     * @param documentEntityField    document's field
     * @param form                   main form
     * @param close                  if need to close popup
     */
    public submitNestedDocument(
        documentId: number,
        documentEntity: Entity,
        documentEntityField: string,
        form: DynamicFormInterface,
        close?: boolean
    ) {
        documentEntity.getProperty<number>(
            documentEntityField
        ).value = documentId;
        form.temporaryDocuments.add(documentId);
        if (close) {
            form.activePopup.close();
        }
    }

    public confirmDocumentsUploadAndReset(
        mainEntity: Entity,
        form: DynamicFormInterface
    ) {
        this.confirmDocumentsUpload(mainEntity, form).subscribe(
            () => (form.temporaryDocuments = new Set()),
            console.error
        );

        if (
            form.temporaryDocumentsOld &&
            form.temporaryDocumentsOld.length > 0
        ) {
            this.confirmDocumentsUploadOld(mainEntity, form).subscribe(
                () => (form.temporaryDocumentsOld = []),
                console.error
            );
        }
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('onDownload')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    StateArg('documentRootField'),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY),
                    ContextArg(ContextArgEnum.FORM),
                ])
                .action(
                    (
                        documentId: number,
                        documentRootField: string,
                        categoryName: string,
                        entity: Entity,
                        form: DynamicFormInterface,
                        fileType: FileType = FileType.ORIGINAL
                    ) =>
                        this.onDownload(
                            documentId,
                            documentRootField,
                            categoryName,
                            entity,
                            form,
                            fileType
                        )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getDownloadUrl')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    StateArg('documentRootField'),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY),
                    ContextArg(ContextArgEnum.FORM),
                ])
                .action(
                    (
                        documentId: number,
                        documentRootField: string,
                        categoryName: string,
                        entity: MainEntity,
                        form: DynamicFormInterface
                    ) =>
                        this.getDownloadUrl(
                            documentId,
                            documentRootField,
                            categoryName,
                            entity,
                            form
                        )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('downloadByUrl')
                .params([StateArg('url')])
                .action((url: string, fileType: FileType = FileType.ORIGINAL) =>
                    this.downloadByUrl(url, fileType)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('removeDocument')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    ContextArg(ContextArgEnum.FORM),
                    ContextArg(ContextArgEnum.CONTEXT_ENTITY),
                    FieldArg('field-name'),
                ])
                .action(
                    (
                        documentId: number,
                        form: DynamicFormInterface,
                        entity: Entity,
                        fieldName: string
                    ) =>
                        this.removeDocument(documentId, form, entity, fieldName)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('removeNestedDocument')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    ContextArg(ContextArgEnum.FORM),
                    ContextArg(ContextArgEnum.ITEM),
                    StateArg('documentEntityField'),
                ])
                .action(
                    (
                        documentId: number,
                        form: DynamicFormInterface,
                        documentEntity: Entity,
                        documentEntityField: string
                    ) =>
                        this.removeDocument(
                            documentId,
                            form,
                            documentEntity,
                            documentEntityField
                        )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('submitDocument')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    FieldArg('field-name'),
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('close'),
                ])
                .action(
                    (
                        documentId: number,
                        fieldName: string,
                        form: DynamicFormInterface,
                        close?: boolean
                    ) =>
                        this.submitNestedDocument(
                            documentId,
                            form.entity,
                            fieldName,
                            form,
                            close
                        )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('submitNestedDocument')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    ContextArg(ContextArgEnum.CONTEXT_ENTITY),
                    FieldArg('field-name'),
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('close'),
                ])
                .action(
                    (
                        documentId: number,
                        documentEntity: Entity,
                        documentEntityField: string,
                        form: DynamicFormInterface,
                        close?: boolean
                    ) =>
                        this.submitNestedDocument(
                            documentId,
                            documentEntity,
                            documentEntityField,
                            form,
                            close
                        )
                )
                .build()
        );

        this.addOldActions(result);

        return result;
    }

    private confirmDocumentsUpload(
        mainEntity: Entity,
        form: DynamicFormInterface
    ): Observable<DocumentInfo[]> {
        return Observable.zip(
            ...Array.from(form.temporaryDocuments).map(docId =>
                this.documentService.confirmUpload(
                    docId,
                    mainEntity.getInstanceId()
                )
            )
        );
    }

    // <editor-fold desc="Old Actions">

    private addOldActions(result: LayoutAction[]) {
        result.push(
            new ActionBuilder()
                .name('submitDocumentOld')
                .action(
                    (
                        event: any,
                        documentSystemName: string,
                        form: DynamicFormComponent,
                        close: boolean
                    ) => {
                        this.upload(
                            form.categorySystemName,
                            documentSystemName,
                            event.file
                        ).subscribe((documentInfo: DocumentInfo) => {
                            form.entity.getProperty<number>(
                                documentSystemName
                            ).value = documentInfo.id;
                            form.temporaryDocumentsOld.push(
                                new TemporaryDocument(
                                    documentInfo,
                                    MetaFieldType.DOCUMENT,
                                    null,
                                    documentSystemName
                                )
                            );
                            if (close) {
                                form.activePopup.close();
                            }
                        }, console.error);
                    }
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('submitNestedDocumentOld')
                .action(
                    (
                        event: any,
                        documentEntity: Entity,
                        fieldSystemName: string,
                        documentSystemName: string,
                        form: DynamicFormComponent,
                        categoryName?: string,
                        entity?: Entity,
                        close?: boolean
                    ) => {
                        categoryName = categoryName || form.categorySystemName;
                        entity = entity || form.entity;

                        this.kbService
                            .getMetaFields(categoryName)
                            .map((metaFields: MetaField[]) => {
                                return metaFields.find(
                                    (metaField: MetaField) => {
                                        // find metaField which contains document
                                        return (
                                            metaField.getSystemName() ===
                                            fieldSystemName
                                        );
                                    }
                                );
                            })
                            .mergeMap((containerMetaField: MetaField) => {
                                return this.upload(
                                    containerMetaField.getCompoundCategorySystemName(),
                                    documentSystemName,
                                    event.file
                                ).map((documentInfo: DocumentInfo) => {
                                    documentEntity.getProperty<number>(
                                        documentSystemName
                                    ).value = documentInfo.id;
                                    this.setDocumentValueToEntity(
                                        containerMetaField,
                                        documentEntity,
                                        entity
                                    );
                                    this.addToTemporaryDocuments(
                                        containerMetaField,
                                        documentInfo,
                                        documentSystemName,
                                        form
                                    );
                                });
                            })
                            .subscribe(() => {
                                if (close) {
                                    form.activePopup.close();
                                }
                            }, console.error);
                    }
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('onDownloadOld')
                .action(
                    (
                        documentId: number,
                        systemName: string,
                        form: DynamicFormComponent,
                        fileType: FileType = FileType.ORIGINAL
                    ): void => {
                        const document: TemporaryDocument = form.temporaryDocumentsOld.find(
                            (tempDoc: TemporaryDocument) => {
                                return tempDoc.info.id === documentId;
                            }
                        );
                        if (document === undefined) {
                            // check if document is confirmed
                            this.deService
                                .getDocumentToken(
                                    form.categorySystemName,
                                    form.entity.getInstanceId(),
                                    systemName
                                )
                                .mergeMap((token: string) => {
                                    return this.documentService.getDownloadUrl(
                                        documentId,
                                        token
                                    );
                                })
                                .subscribe((url: string) => {
                                    this.documentService.downloadDocument(
                                        url,
                                        fileType
                                    );
                                }, console.error);
                        } else {
                            return this.documentService.downloadTempDocument(
                                documentId
                            );
                        }
                    }
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getDownloadUrlOld')
                .action(
                    (
                        documentId: number,
                        systemName: string,
                        form: DynamicFormComponent
                    ): Observable<string> => {
                        const document: TemporaryDocument = form.temporaryDocumentsOld.find(
                            (tempDoc: TemporaryDocument) => {
                                return tempDoc.info.id === documentId;
                            }
                        );
                        const downloadUrl: ReplaySubject<string> = new ReplaySubject<
                            string
                        >(1);
                        if (document === undefined) {
                            this.deService
                                .getDocumentToken(
                                    form.categorySystemName,
                                    form.entity.getInstanceId(),
                                    systemName
                                )
                                .mergeMap((token: string) => {
                                    return this.documentService.getDownloadUrl(
                                        documentId,
                                        token
                                    );
                                })
                                .subscribe(
                                    (url: string) => downloadUrl.next(url),
                                    console.error
                                );
                        } else {
                            this.documentService
                                .getTempDocumentUrl(documentId)
                                .subscribe(
                                    (url: string) => downloadUrl.next(url),
                                    console.error
                                );
                        }
                        return downloadUrl;
                    }
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('removeDocumentOld')
                .action(
                    (documentId: number, form: DynamicFormComponent): void => {
                        const documentIndex: number = form.temporaryDocumentsOld.findIndex(
                            (tempDoc: TemporaryDocument) => {
                                return tempDoc.info.id === documentId;
                            }
                        );
                        if (documentIndex !== -1) {
                            const document: TemporaryDocument =
                                form.temporaryDocumentsOld[documentIndex];
                            if (document.entitySystemName === null) {
                                form.entity.getProperty<number>(
                                    document.documentSystemName
                                ).value = null;
                            } else {
                                this.kbService
                                    .getMetaFields(form.categorySystemName)
                                    .subscribe((metaFields: MetaField[]) => {
                                        const metaField: MetaField = metaFields.find(
                                            (mf: MetaField) => {
                                                return (
                                                    mf.getSystemName() ===
                                                    document.entitySystemName
                                                );
                                            }
                                        );
                                        this.removeDocumentValueFromEntity(
                                            metaField,
                                            document,
                                            form
                                        );
                                    }, console.error);
                            }
                            form.temporaryDocumentsOld.splice(documentIndex, 1);
                        }
                    }
                )
                .build()
        );

        return result;
    }

    private confirmDocumentsUploadOld(
        mainEntity: Entity,
        form: DynamicFormInterface
    ): Observable<DocumentInfo[]> {
        return Observable.zip(
            ...form.temporaryDocumentsOld.map((doc: TemporaryDocument) => {
                let document: Entity;
                switch (doc.type) {
                    case MetaFieldType.MULTI_SELECT:
                    case MetaFieldType.SUB_ENTITY:
                        document = mainEntity
                            .getProperty<Entity[]>(doc.entitySystemName)
                            .value.find((entity: Entity) => {
                                return (
                                    entity.getProperty<number>(
                                        doc.documentSystemName
                                    ).value === doc.info.id
                                );
                            });
                        if (document) {
                            return this.documentService.confirmUpload(
                                doc.info.id,
                                mainEntity.getInstanceId()
                            );
                        } else {
                            return Observable.of(null);
                        }
                    case MetaFieldType.DOCUMENT:
                        return this.documentService.confirmUpload(
                            doc.info.id,
                            mainEntity.getInstanceId()
                        );
                    case MetaFieldType.CLASSIFIER:
                        document = mainEntity.getProperty<Classifier>(
                            doc.entitySystemName
                        ).value;
                        if (
                            document.getProperty<number>(doc.documentSystemName)
                                .value === doc.info.id
                        ) {
                            return this.documentService.confirmUpload(
                                doc.info.id,
                                mainEntity.getInstanceId()
                            );
                        } else {
                            return Observable.of(null);
                        }
                    default:
                        throw new TypeError(
                            `Unsupported type ${doc.type} for document!`
                        );
                }
            })
        );
    }

    private upload(
        categorySystemName: string,
        fieldSystemName: string,
        file: File
    ): Observable<DocumentInfo> {
        return this.documentService.upload(
            `${categorySystemName}.${fieldSystemName}`,
            file.name,
            file
        );
    }

    private setDocumentValueToEntity(
        documentMetaField: MetaField,
        document: Entity,
        entity: Entity
    ): void {
        switch (documentMetaField.getType()) {
            case MetaFieldType.CLASSIFIER:
                entity.getProperty<Entity>(
                    documentMetaField.getSystemName()
                ).value = document;
                return;
            case MetaFieldType.MULTI_SELECT:
                const list: Entity[] = entity.getProperty<Entity[]>(
                    documentMetaField.getSystemName()
                ).value;
                if (
                    list.find(
                        (item: Entity) => item.getId() === document.getId()
                    ) === undefined
                ) {
                    list.push(document);
                }
                return;
            case MetaFieldType.SUB_ENTITY:
                const subEntities: SubEntity[] = entity.getProperty<
                    SubEntity[]
                >(documentMetaField.getSystemName()).value;
                if (
                    subEntities.find(
                        (item: SubEntity) => item.getId() === document.getId()
                    ) === undefined
                ) {
                    const subEntity: SubEntity = document as SubEntity;
                    subEntity.setId(
                        getUniqueIdForSubEntity(
                            entity as MainEntity,
                            documentMetaField.getSystemName()
                        )
                    );
                    subEntities.push(subEntity);
                }
                return;
            default:
                throw new TypeError(
                    `${documentMetaField
                        .getType()
                        .toString()} type not supported for document`
                );
        }
    }

    private removeDocumentValueFromEntity(
        documentMetaField: MetaField,
        document: TemporaryDocument,
        form: DynamicFormComponent
    ): void {
        switch (documentMetaField.getType()) {
            case MetaFieldType.CLASSIFIER: {
                form.entity.getProperty<Entity>(
                    document.entitySystemName
                ).value = null;
                return;
            }
            case MetaFieldType.MULTI_SELECT: {
                const documentIndex: number = form.entity
                    .getProperty<Entity[]>(document.entitySystemName)
                    .value.findIndex((entity: Entity) => {
                        return (
                            entity.getProperty<number>(
                                document.documentSystemName
                            ).value === document.info.id
                        );
                    });
                if (documentIndex !== -1) {
                    form.entity
                        .getProperty<Entity[]>(document.entitySystemName)
                        .value.splice(documentIndex, 1);
                }
                return;
            }
            case MetaFieldType.SUB_ENTITY: {
                form.entity
                    .getProperty<Entity[]>(document.entitySystemName)
                    .value.some((entity: Entity) => {
                        if (
                            entity.getProperty<number>(
                                document.documentSystemName
                            ).value === document.info.id
                        ) {
                            entity.getProperty<number>(
                                document.documentSystemName
                            ).value = null;
                            return true;
                        }
                        return false;
                    });
                return;
            }
            default:
                throw new TypeError(
                    `${documentMetaField
                        .getType()
                        .toString()} type not supported for document`
                );
        }
    }

    private addToTemporaryDocuments(
        metaField: MetaField,
        info: DocumentInfo,
        documentSystemName: string,
        form: DynamicFormComponent
    ): void {
        form.temporaryDocumentsOld.push(
            new TemporaryDocument(
                info,
                metaField.getType(),
                metaField.getSystemName(),
                documentSystemName
            )
        );
    }

    // </editor-fold>
}

/* tslint:disable:max-classes-per-file */

export class TemporaryDocument {
    constructor(
        public info: DocumentInfo,
        public type: MetaFieldType,
        public entitySystemName: string,
        public documentSystemName: string
    ) {}
}

/* tslint:enable:max-classes-per-file */
