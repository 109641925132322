import {Injectable} from '@angular/core';
import {MainEntity, SubEntity} from '@synisys/idm-de-service-client-js';
import {
    ActionBuilder,
    FieldArg,
    LayoutAction,
    StateArg,
} from '../layout-actions';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {Observable} from 'rxjs/Observable';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {mergeMap} from 'rxjs/operators';
import {LocaleService} from '@synisys/idm-locale-service-client-js';
import {FormattingService} from '@synisys/idm-frontend-shared';
import {of} from 'rxjs/observable/of';

@Injectable()
export class DynamicFormUtilitiesService {
    constructor(
        private readonly messageService: MessageService,
        private readonly kbService: KbService,
        private readonly currentLanguageProvider: CurrentLanguageProvider,
        private readonly localeService: LocaleService,
        private readonly formattingService: FormattingService
    ) {}

    public formatDate(date: Date): string {
        if (!date) {
            return '';
        }
        const day = date.getDate();
        const monthId = date.getMonth();
        const year = date.getFullYear();
        const months: string[] = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];

        return day + ' ' + months[monthId] + ' ' + year;
    }

    public formatDateWithTime(date: Date): string {
        if (!date) {
            return '';
        }
        const months: string[] = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const day = date.getDate();
        const monthId = date.getMonth();
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();

        return (
            day +
            ' ' +
            months[monthId] +
            ' ' +
            year +
            ' ' +
            this._toTwoDigit(hour) +
            ':' +
            this._toTwoDigit(minute) +
            ':' +
            this._toTwoDigit(second)
        );
    }

    public getImageClass(document: any): string {
        const fileName = document.getProperty('DOCUMENTNAME').value;
        const fileExtension = fileName.split('.').pop();
        switch (fileExtension) {
            case 'archive':
                return 'fa fa-file-archive-o fa-3x';
            case 'text':
                return 'fa fa-file-text-o fa-3x';
            case 'excel':
                return 'fa fa-file-excel-o fa-3x';
            case 'word':
                return 'fa fa-file-word-o fa-3x';
            case 'pdf':
                return 'fa fa-file-pdf-o fa-3x';
            case 'jpg':
            case 'png':
            case 'gif':
                return 'fa fa-file-image-o fa-3x';
            case 'ppt':
                return 'fa fa-file-powerpoint-o fa-3x';
            default:
                return 'fa fa-file-o fa-3x';
        }
    }

    public changeRadioValue(
        mainEntity: MainEntity,
        systemName: string,
        selectedValue: any,
        subEntity?: SubEntity
    ): void {
        if (subEntity) {
            subEntity.getProperty(systemName).value = selectedValue;
        } else {
            mainEntity.getProperty(systemName).value = selectedValue;
        }
    }

    public isItemSelected(
        entity: MainEntity,
        item: SubEntity,
        systemName: string
    ): boolean {
        const selectedItemsList: SubEntity[] = entity.getProperty<SubEntity[]>(
            systemName
        ).value;
        return selectedItemsList.some((selectedItem: SubEntity) => {
            return item.getId() === selectedItem.getId();
        });
    }

    public getMessage(messageKey: string): Observable<string> {
        return this.messageService.getMessage(messageKey);
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('formatDate')
                .params([StateArg('date')])
                .action(this.formatDate)
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getMessage')
                .params([StateArg('messageKey')])
                .action(this.getMessage)
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getNameMetaFieldIds')
                .params([FieldArg('field-category')])
                .action((categorySystemName: string) =>
                    this.kbService.getNameMetaFieldIds(categorySystemName)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getCurrentLocale')
                .params([])
                .action(() =>
                    this.currentLanguageProvider
                        .getCurrentLanguage()
                        .pipe(
                            mergeMap((currentLanguage: Language) =>
                                this.localeService.getLocaleInfo(
                                    currentLanguage.getId()
                                )
                            )
                        )
                )
                .build()
        );

        // TODO: replace messageKey when specialized one is added for de
        result.push(
            new ActionBuilder()
                .name('getChooseDatePlaceholder')
                .params([])
                .action(() =>
                    this.messageService.getMessage(
                        'portfolio.filter.date.range.choose.date'
                    )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('sysLocalDate')
                .params([])
                .action(() => this.transformDate.bind(this))
                .build()
        );

        return result;
    }

    private _toTwoDigit(digit: number): string {
        return digit < 10 ? '0' + digit : digit.toString();
    }

    private transformDate(
        date: number,
        format: string = 'date'
    ): Observable<string> {
        switch (format) {
            case 'date':
                return this.formattingService.formatDate(date);
            case 'dateTime':
                return this.formattingService.formatDateTime(date);
            case 'time':
                return this.formattingService.formatTime(date);
        }
        return of('');
    }
}
