import {ControlModel} from '@synisys/idm-dynamic-layout-interpreter';
import {LayoutAction} from './layout-action.builder';
import {join} from 'lodash';
import {DynamicArgument} from './dynamic-argument.type';
import {ContextEntity} from '../../component';

export class ControlActionCallGenerator {
    public generateCall(
        controlModel: ControlModel,
        layoutAction: LayoutAction,
        isAsync: boolean,
        contextEntity: ContextEntity | undefined
    ) {
        const args: string[] = layoutAction.metaArgs.map(
            (arg: DynamicArgument) => {
                return arg.resolve(controlModel, contextEntity);
            }
        );

        const generatedCall = `actions.${layoutAction.name}(${join(
            args,
            ','
        )})`;

        return isAsync ? generatedCall + ' | async' : generatedCall;
    }
}
