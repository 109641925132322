import {Injectable} from '@angular/core';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {DeService} from '@synisys/idm-de-service-client-js';
import {DocumentAuthProviderService} from '@synisys/idm-document-service-client-js';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
} from '@synisys/idm-kb-service-client-js';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {isNil} from 'lodash';

@Injectable()
export class DeDocumentTokenProvider implements DocumentAuthProviderService {
    private UPLOAD_PERMISSION_TYPE = 'UPLOAD';

    constructor(
        private classifierService: ClassifierService,
        private deService: DeService,
        private kbService: KbService
    ) {}

    public getDocumentAuthInfo(
        categorySystemName: string,
        id: number,
        documentSystemName: string
    ): Observable<string> {
        return this.kbService
            .getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(categorySystemName)
            )
            .pipe(
                mergeMap((category: MetaCategory) => {
                    if (category.getIsClassifier()) {
                        return this.classifierService.getDocumentAuthInfo(
                            categorySystemName,
                            id,
                            documentSystemName
                        );
                    } else {
                        return this.deService.getDocumentToken(
                            categorySystemName,
                            id,
                            documentSystemName
                        );
                    }
                })
            );
    }

    public getDocumentAuthInfoForUpload(
        categorySystemName: string,
        id: number,
        documentSystemName: string
    ): Observable<string> {
        if (isNil(id)) {
            id = -1;
        }
        return this.kbService
            .getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(categorySystemName)
            )
            .pipe(
                mergeMap((category: MetaCategory) => {
                    if (!category.getIsClassifier()) {
                        return this.deService.getDocumentToken(
                            categorySystemName,
                            id,
                            documentSystemName,
                            this.UPLOAD_PERMISSION_TYPE
                        );
                    }
                    throw new Error(
                        'Upload Document Auth Info is not supported for classifers'
                    );
                })
            );
    }
}
