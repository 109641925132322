import {Component} from '@angular/core';

import {PageComponent} from '@synisys/idm-de-service-client-js';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-already-deleted-page',
             templateUrl: 'already-deleted-page.component.html',
           })
export class AlreadyDeletedPageComponent extends PageComponent {
}
