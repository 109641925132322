import {Injectable} from '@angular/core';
import {Classifier} from '@synisys/idm-classifier-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DeSerializationService,
    DeService,
    SubEntity,
} from '@synisys/idm-de-service-client-js';
import {
    FormData,
    FormService,
    FormType,
} from '@synisys/idm-dynamic-layout-interpreter';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {
    ValidationsCalculationsData,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {DynamicLayoutType} from '../../component/dynamic-layout';
import {PopupData, PopupSubFormComponent} from '../../component/popup';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    FieldArg,
    LayoutAction,
} from '../layout-actions';
import {DynamicFormInterface} from './dynamic-from.interface';
import {ActionNotifierKey} from '../../concepts/action-notifier-key.enum';
import {ActionNotificationData} from '../../concepts/action-notification-data.model';

@Injectable()
export class DynamicFormPopupsService {
    constructor(
        private validationService: ValidationService,
        private deSerializationService: DeSerializationService,
        private deService: DeService,
        private readonly formService: FormService,
        private readonly kbService: KbService
    ) {}

    public openEditPopup(
        form: DynamicFormInterface,
        categoryName: string,
        subFormKey: string,
        subEntity: SubEntity,
        formId?: number
    ): void {
        form.popupEditMode = true;
        this.openPopupAction(form, categoryName, subFormKey, subEntity, formId);
    }

    public openPopup(
        form: DynamicFormInterface,
        categoryName: string,
        subFormKey: string,
        subEntity: SubEntity,
        formId?: number
    ): void {
        form.popupEditMode = false;
        if (subEntity) {
            this.openPopupAction(
                form,
                categoryName,
                subFormKey,
                subEntity,
                formId
            );
        } else {
            this.deService
                .createBlankSubEntity(categoryName)
                .subscribe((_subEntity: SubEntity) => {
                    this.openPopupAction(
                        form,
                        categoryName,
                        subFormKey,
                        _subEntity,
                        formId
                    );
                }, console.error);
        }
    }

    public addSubEntity(
        form: DynamicFormInterface,
        item: SubEntity,
        fieldSystemName: string,
        close?: boolean
    ): void {
        const validationService = this.validationService;
        const categoryName = form.metaForm.layoutModel.category;
        const entity: Entity = form.entity;
        const activePopup = form.activePopup;
        const validations = [];
        this.cloneEntityAndDeserializeOnAdd(
            form,
            item,
            fieldSystemName
        ).subscribe((serialized: Entity) => {
            validationService
                .calculateAndValidateField(
                    categoryName,
                    fieldSystemName,
                    serialized,
                    this.getWorkflowStateId(form)
                )
                .subscribe((valCalcData: ValidationsCalculationsData) => {
                    if (valCalcData.getValidations().length > 0) {
                        valCalcData.getValidations().map((validation: any) => {
                            for (const relatedFieldSystemName of validation.getRelatedMetaFieldIds()) {
                                if (
                                    relatedFieldSystemName.getSystemName() !==
                                    fieldSystemName
                                ) {
                                    validations.push(validation);
                                } else {
                                    activePopup.popupValidationMessage = validation.getMessage();
                                }
                            }
                        });
                    } else {
                        this.deSerializationService
                            .deserializeMainEntity(
                                categoryName,
                                Observable.of(valCalcData.getData())
                            )
                            .subscribe((newEntity: Entity) => {
                                form.entity.getProperty(
                                    fieldSystemName
                                ).value = newEntity.getProperty(
                                    fieldSystemName
                                ).value;
                                form.actionNotifier.next(
                                    new ActionNotificationData(
                                        ActionNotifierKey.SUB_FORM_SAVE_ACTION,
                                        undefined
                                    )
                                );
                            }, console.error);
                        if (close) {
                            activePopup.close();
                        }
                        validations.length = 0;
                    }

                    form.setPopupValidations(validations);
                }, console.error);
        }, console.error);
    }

    public editSubEntity(
        form: DynamicFormInterface,
        item: SubEntity,
        fieldSystemName: string,
        close?: boolean
    ): void {
        const validationService = this.validationService;
        const categoryName = form.metaForm.layoutModel.category;
        const entity: Entity = form.entity;
        const activePopup = form.activePopup;
        const validations = [];

        this.cloneEntityAndDeserializeOnEdit(
            form,
            item,
            fieldSystemName
        ).subscribe((serialized: object) => {
            validationService
                .calculateAndValidateField(
                    categoryName,
                    fieldSystemName,
                    serialized,
                    this.getWorkflowStateId(form)
                )
                .subscribe((valCalcData: ValidationsCalculationsData) => {
                    if (valCalcData.getValidations().length > 0) {
                        valCalcData.getValidations().map((validation: any) => {
                            for (const relatedFieldSystemName of validation.getRelatedMetaFieldIds()) {
                                if (
                                    relatedFieldSystemName.getSystemName() !==
                                    fieldSystemName
                                ) {
                                    validations.push(validation);
                                } else {
                                    activePopup.popupValidationMessage = validation.getMessage();
                                }
                            }
                        });
                    } else {
                        this.deSerializationService
                            .deserializeMainEntity(
                                categoryName,
                                Observable.of(valCalcData.getData())
                            )
                            .subscribe((newEntity: Entity) => {
                                form.entity.getProperty(
                                    fieldSystemName
                                ).value = newEntity.getProperty(
                                    fieldSystemName
                                ).value;

                                form.actionNotifier.next(
                                    new ActionNotificationData(
                                        ActionNotifierKey.SUB_FORM_SAVE_ACTION,
                                        undefined
                                    )
                                );
                            }, console.error);

                        if (close) {
                            form.activePopup.close();
                        }
                        validations.length = 0;
                    }

                    form.setPopupValidations(validations);
                }, console.error);
        }, console.error);
    }

    public onCancelPopup(
        form: DynamicFormInterface,
        popupFieldCategory: string,
        popupFieldName: string
    ): void {
        if (form.metaForm.formType === FormType.VIEW) {
            form.activePopup.close();
            return;
        }
        this.kbService
            .getMetaFields(popupFieldCategory)
            .pipe(
                map((metaFields: MetaField[]) => {
                    if (!form.entity.getId()) {
                        this.clearEntity(form.activePopup.entity, metaFields);
                    } else {
                        // reset from old entity
                        const popupOldEntity = form.oldEntity
                            .getProperty<Entity[]>(popupFieldName)
                            .value.find(
                                (entity: Entity) =>
                                    entity.getId() ===
                                    form.activePopup.entity.getId()
                            );
                        if (popupOldEntity) {
                            this.restoreEntityFromOld(
                                form.activePopup.entity,
                                popupOldEntity,
                                metaFields
                            );
                        } else {
                            this.clearEntity(
                                form.activePopup.entity,
                                metaFields
                            );
                        }
                    }
                    form.activePopup.close();
                    form.actionNotifier.next(
                        new ActionNotificationData(
                            ActionNotifierKey.SUB_FORM_CLOSE_ACTION,
                            undefined
                        )
                    );
                })
            )
            .subscribe(noop, console.error);
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('openEditPopup')
                .action(
                    (
                        form: DynamicFormInterface,
                        categoryName: string,
                        subFormKey: string,
                        subEntity: SubEntity,
                        formId?: number
                    ) =>
                        this.openEditPopup(
                            form,
                            categoryName,
                            subFormKey,
                            subEntity,
                            formId
                        )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('openPopup')
                .action(
                    (
                        form: DynamicFormInterface,
                        categoryName: string,
                        subFormKey: string,
                        subEntity: SubEntity,
                        formId?: number
                    ) =>
                        this.openPopup(
                            form,
                            categoryName,
                            subFormKey,
                            subEntity,
                            formId
                        )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('addSubEntity')
                .action(
                    (
                        form: DynamicFormInterface,
                        item: SubEntity,
                        fieldSystemName: string,
                        close?: boolean
                    ) => this.addSubEntity(form, item, fieldSystemName, close)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('editSubEntity')
                .action(
                    (
                        form: DynamicFormInterface,
                        item: SubEntity,
                        fieldSystemName: string,
                        close?: boolean
                    ) => this.editSubEntity(form, item, fieldSystemName, close)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('onCancelPopup')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    FieldArg('field-category'),
                    FieldArg('field-name'),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        popupFieldCategory: string,
                        popupFieldName: string
                    ) =>
                        this.onCancelPopup(
                            form,
                            popupFieldCategory,
                            popupFieldName
                        )
                )
                .build()
        );

        return result;
    }

    private openPopupAction(
        dynamicForm: DynamicFormInterface,
        categoryName: string,
        subFormKey: string,
        subEntity: SubEntity,
        formId: number
    ): void {
        this.formService
            .loadForm(subFormKey, formId, categoryName)
            .subscribe((formData: FormData) => {
                const popupProperties = formData.model.content.properties.generalProperties.get(
                    'popup_properties'
                );
                const width: string = popupProperties['width'];
                // const height: string = popupProperties['height'];
                const title: string = popupProperties['titleString'];
                const dialogRef = dynamicForm.dialog.open<
                    PopupSubFormComponent,
                    PopupData
                >(PopupSubFormComponent, {
                    disableClose:
                        dynamicForm.metaForm.formType === FormType.EDIT,
                    // height: height,
                    width: width,
                    data: {
                        form: dynamicForm,
                        formId,
                        formKey: subFormKey,
                        entity: subEntity,
                        categoryName,
                        title,
                        layoutType: DynamicLayoutType.subForm,
                    },
                });
                dynamicForm.activePopup = dialogRef.componentInstance;
            }, console.error);
    }

    private getWorkflowStateId(form: DynamicFormInterface): number {
        const wfStateMetaField: MetaField = form.metaForm.wfStateMetaField;
        if (wfStateMetaField) {
            return form.entity.getProperty<Classifier>(
                wfStateMetaField.getSystemName()
            ).value
                ? form.entity
                      .getProperty<Classifier>(wfStateMetaField.getSystemName())
                      .value.getId()
                : undefined;
        }
        return undefined;
    }

    private cloneEntityAndDeserializeOnEdit(
        form: DynamicFormInterface,
        item: SubEntity,
        fieldSystemName: string
    ): Observable<any> {
        const entity: Entity = form.entity;
        const categoryName = form.metaForm.layoutModel.category;
        const withEditedSubformEntity = entity.clone();
        const subEntityIndex = withEditedSubformEntity
            .getProperty<SubEntity[]>(fieldSystemName)
            .value.findIndex((sub: SubEntity) => sub.getId() === item.getId());
        withEditedSubformEntity.getProperty<SubEntity[]>(fieldSystemName).value[
            subEntityIndex
        ] = item;

        return this.deSerializationService.serializeEntity(
            categoryName,
            withEditedSubformEntity
        );
    }

    private cloneEntityAndDeserializeOnAdd(
        form: DynamicFormInterface,
        item: SubEntity,
        fieldSystemName: string
    ): Observable<Entity> {
        const entity: Entity = form.entity;
        const categoryName = form.metaForm.layoutModel.category;
        const withNewSubformEntity = entity.clone();
        withNewSubformEntity
            .getProperty<SubEntity[]>(fieldSystemName)
            .value.push(item);

        return this.deSerializationService.serializeEntity(
            categoryName,
            withNewSubformEntity
        );
    }

    private clearEntity(entity: Entity, metaFields: MetaField[]): void {
        if (!entity) {
            return;
        }
        metaFields.forEach((metaField: MetaField) => {
            const fieldType = metaField.getType();
            entity.getProperty(metaField.getSystemName()).value =
                // array type fields
                fieldType === MetaFieldType.MULTI_SELECT ||
                fieldType === MetaFieldType.SUB_ENTITY
                    ? []
                    : // value setter doesn't accept undefined
                      null;
        });
    }

    private restoreEntityFromOld(
        entity: Entity,
        oldEntity: Entity,
        metaFields: MetaField[]
    ): void {
        if (!entity) {
            return;
        }
        metaFields.forEach((metaField: MetaField) => {
            const fieldName = metaField.getSystemName();
            entity.getProperty(fieldName).value = oldEntity.getProperty(
                fieldName
            ).value;
        });
    }
}
