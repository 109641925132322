import {NavigationEnd, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {filter, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';

@Injectable()
export class RoutingHistoryService {
  private static readonly HISTORY_BUFFER_SIZE = 300;
  private static readonly HISTORY_BUFFER_TO_CLEAN_SIZE = 100;

  private _history: string[] = [];

  constructor(private router: Router) {
    this.subscribeToNavigationChange();
  }

  public subscribeToNavigationChange(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(({urlAfterRedirects}: NavigationEnd) => {
        this._history.push(urlAfterRedirects);
        if (this._history.length > RoutingHistoryService.HISTORY_BUFFER_SIZE) {
          this.clearOldHistory();
        }
      })
    ).subscribe(noop, console.error);
  }

  public getHistory(): string[] {
    return this._history;
  }

  private clearOldHistory(): void {
    this._history.splice(0, RoutingHistoryService.HISTORY_BUFFER_TO_CLEAN_SIZE);
  }
}
