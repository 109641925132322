import {Injectable} from '@angular/core';
import {DeService, MainEntity} from '@synisys/idm-de-service-client-js';
import {
    FormData,
    FormService,
    FormType,
} from '@synisys/idm-dynamic-layout-interpreter';
import {FormResolverService} from '@synisys/idm-frontend-shared';
import {
    ActionBuilder,
    LayoutAction,
} from '../layout-actions/layout-action.builder';
import {
    ContextArg,
    ContextArgEnum,
    StateArg,
} from '../layout-actions/dynamic-argument.type';
import {Entity} from '@synisys/idm-de-core-frontend';
import {DynamicFormComponent} from '../../component/form/form.component';
import {ActivatedRoute} from '@angular/router';
import {zip} from 'rxjs/observable/zip';
import {mergeMap, switchMap, take} from 'rxjs/operators';
import {DynamicFormInterface} from './dynamic-from.interface';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class DynamicFormNavigationsService {
    constructor(
        private formResolverService: FormResolverService,
        private formService: FormService,
        private route: ActivatedRoute,
        private deService: DeService
    ) {}

    public toEdit(
        form: DynamicFormInterface,
        formName: string,
        categoryName: string,
        entityId: number,
        formId: number = 0
    ): Promise<boolean> {
        return this.deService
            .loadEntityByInstanceId(categoryName, entityId)
            .pipe(
                switchMap(() => {
                    return this.route.fragment;
                }),
                switchMap(fragment =>
                    this.formResolverService.navigateToEditDeForm(
                        categoryName,
                        formName,
                        entityId,
                        formId,
                        DynamicFormComponent.QUERY_PARAMS,
                        fragment
                    )
                ),
                take(1)
            )
            .catch(err => {
                if (err.status === 404) {
                    form.showNotification('de_entity_already_deleted', 'Error', 'de_save_error_mode');
                    return Observable.of(false);
                }
            })
            .toPromise();
    }

    public toDefaultEditForm(
        form: DynamicFormInterface,
        categoryName: string,
        entity: MainEntity
    ): Promise<boolean> {
        return this.deService
            .loadEntityByInstanceId(categoryName, entity.getInstanceId())
            .pipe(
                switchMap(() => {
                    return zip(
                        this.formService.allFormsByCategoryAndType(
                            categoryName,
                            FormType.EDIT
                        ),
                        this.route.fragment
                    );
                }),
                switchMap(
                    (data: [FormData[], string]): Promise<boolean> => {
                        const firstForm: FormData = data[0][0];
                        return this.formResolverService.navigateToEditDeForm(
                            categoryName,
                            firstForm.model.formName,
                            entity.getInstanceId(),
                            firstForm.id,
                            DynamicFormComponent.QUERY_PARAMS,
                            data[1]
                        );
                    }
                ),
                take(1)
            )
            .catch(err => {
                if (err.status === 404) {
                    form.showNotification('de_entity_already_deleted', 'Error', 'de_save_error_mode');
                    return Observable.of(false);
                }
            })
            .toPromise();
    }

    public toDefaultViewForm(
        form: DynamicFormInterface,
        categoryName: string,
        entity: Entity
    ): Promise<boolean> {
        return this.deService
            .loadEntityByInstanceId(categoryName, entity.getInstanceId())
            .pipe(
                switchMap(() => {
                    return zip(
                        this.formService.allFormsByCategoryAndType(
                            categoryName,
                            FormType.VIEW
                        ),
                        this.route.fragment
                    );
                }),
                switchMap(
                    (data: [FormData[], string]): Promise<boolean> => {
                        const firstForm: FormData = data[0][0];
                        return this.formResolverService.navigateToViewDeForm(
                            categoryName,
                            firstForm.model.formName,
                            entity.getInstanceId(),
                            firstForm.id,
                            DynamicFormComponent.QUERY_PARAMS,
                            data[1]
                        );
                    }
                ),
                take(1)
            )
            .catch(err => {
                if (err.status === 404) {
                    form.showNotification('de_entity_already_deleted', 'Error', 'de_save_error_mode');
                    return Observable.of(false);
                }
            })
            .toPromise();
    }

    public toView(
        form: DynamicFormInterface,
        formName: string,
        categoryName: string,
        entityId: number,
        formId: number = 0
    ): Promise<boolean> {
        return this.deService
            .loadEntityByInstanceId(categoryName, entityId)
            .pipe(
                switchMap(() => {
                    return this.route.fragment;
                }),
                switchMap(fragment =>
                    this.formResolverService.navigateToViewDeForm(
                        categoryName,
                        formName,
                        entityId,
                        formId,
                        DynamicFormComponent.QUERY_PARAMS,
                        fragment
                    )
                ),
                take(1)
            )
            .catch(err => {
                if (err.status === 404) {
                    form.showNotification('de_entity_already_deleted', 'Error', 'de_save_error_mode');
                    return Observable.of(false);
                }
            })
            .toPromise();
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('toEdit')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('formName'),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY_ID),
                    StateArg('formId'),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        formName: string,
                        categoryName: string,
                        entityId: number,
                        formId: number = 0
                    ) =>
                        this.toEdit(
                            form,
                            formName,
                            categoryName,
                            entityId,
                            formId
                        )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('toDefaultEditForm')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        categoryName: string,
                        entity: MainEntity
                    ) => this.toDefaultEditForm(form, categoryName, entity)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('toDefaultViewForm')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        categoryName: string,
                        entity: MainEntity
                    ) => this.toDefaultViewForm(form, categoryName, entity)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('toDefaultViewForm')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        categoryName: string,
                        entity: MainEntity
                    ) => this.toDefaultViewForm(form, categoryName, entity)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('toView')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('formName'),
                    ContextArg(ContextArgEnum.CATEGORY),
                    ContextArg(ContextArgEnum.ENTITY_ID),
                    StateArg('formId'),
                ])
                .action(
                    (
                        form: DynamicFormInterface,
                        formName: string,
                        categoryName: string,
                        entityId: number,
                        formId: number = 0
                    ) =>
                        this.toView(
                            form,
                            formName,
                            categoryName,
                            entityId,
                            formId
                        )
                )
                .build()
        );

        return result;
    }
}
