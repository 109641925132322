import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {PermissionType} from '@synisys/idm-authorization-client-js';
import {
    DePermission,
    DePermissionService,
} from '@synisys/idm-de-service-client-js';
import {
    FormData,
    FormService,
    FormType,
} from '@synisys/idm-dynamic-layout-interpreter';
import {isNaN, isNil, memoize} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {DePermissionGuard} from '../de-permission.guard';
import {map, mergeMap, switchMap} from 'rxjs/operators';

@Injectable()
export class OnlineDePermissionGuard extends DePermissionGuard {
    constructor(
        private dePermissionService: DePermissionService,
        private router: Router,
        private formService: FormService
    ) {
        super();
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const systemName: string = route.params.categoryName;
        const formName: string = route.params.formName;
        const permissionType: PermissionType =
            route.data.categoryPermissionType;
        const formId: number = !isNaN(Number(route.params.formId))
            ? Number(route.params.formId)
            : undefined;
        const instanceId: number = route.params.id;

        if (permissionType === undefined && (formId || formId === 0)) {
            // in view/edit forms we can't get permission type from route
            return this.formService
                .loadFormTypeId(formName, formId, systemName)
                .pipe(
                    switchMap((type: number) => {
                        return this.hasPermissionBy(
                            systemName,
                            this.getPermissionType(type),
                            instanceId
                        );
                    })
                );
        } else {
            return this.dePermissionService.hasCreatePermissionByCategory(
                systemName
            );
        }
    }

    private hasPermissionBy(
        systemName: string,
        permissionType: PermissionType,
        instanceId: number
    ): Observable<boolean> {
        return this.dePermissionService
            .getCategoryDePermissionsByInstance(systemName, instanceId)
            .pipe(
                map((dePermission: DePermission) => {
                    const hasPermission = this.extractPermissionFrom(
                        dePermission,
                        permissionType
                    );
                    if (!hasPermission) {
                        this.router.navigate([
                            'dynamic-de/no-permission',
                            this.getPermissionNameFrom(permissionType),
                        ]);
                    }
                    return hasPermission;
                })
            );
    }

    private getPermissionType(formType: FormType): PermissionType {
        switch (formType) {
            case FormType.EDIT:
                return PermissionType.EDIT;
            case FormType.VIEW:
                return PermissionType.VIEW;
            default:
                throw new Error('Unknown form type!!!');
        }
    }

    private getPermissionNameFrom(permissionType: PermissionType): string {
        switch (permissionType) {
            case PermissionType.ADD:
                return 'add';
            case PermissionType.VIEW:
                return 'view';
            case PermissionType.EDIT:
                return 'edit';
            case PermissionType.DELETE:
                return 'delete';
            default:
                throw new Error('Unknown category permission type!!!');
        }
    }

    private extractPermissionFrom(
        dePermission: DePermission,
        permissionType: PermissionType
    ): boolean {
        switch (permissionType) {
            case PermissionType.ADD:
                return dePermission.canCreate;
            case PermissionType.VIEW:
                return dePermission.canView;
            case PermissionType.EDIT:
                return dePermission.canEdit;
            case PermissionType.DELETE:
                return dePermission.canDelete;
        }
    }

    private isAtSameUrl(
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean {
        if (!isNil(nextState)) {
            return (
                nextState.url.split('#')[0] === currentState.url.split('#')[0]
            );
        }

        return false;
    }
}
