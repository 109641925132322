import {DynamicArgument} from './dynamic-argument.type';
import {
    ControlModel,
    getOptionalProperty,
    getRequiredProperty,
} from '@synisys/idm-dynamic-layout-interpreter';
import {endsWith, split} from 'lodash';
import {ContextEntity} from '../../component';

enum FieldPostFix {
    NAME = '-name',
    CATEGORY = '-category',
    ID = '-id',
    PARENT = '-parent',
}

export class FieldArgument implements DynamicArgument {
    private readonly value: string;
    constructor(arg: string) {
        this.value = arg;
    }

    public resolve(
        controlModel: ControlModel,
        contextEntity: ContextEntity | undefined
    ): string {
        return this.resolveByPostFix(controlModel, contextEntity);
    }

    private resolveByPostFix(
        controlModel: ControlModel,
        contextEntity: ContextEntity | undefined
    ): string {
        const fieldKey = this.getFieldKey(controlModel);
        if (controlModel.fields[fieldKey]) {
            if (endsWith(this.value, FieldPostFix.NAME)) {
                return `'${getRequiredProperty(
                    controlModel.fields[fieldKey],
                    'metaFieldId.systemName'
                )}'`;
            } else if (endsWith(this.value, FieldPostFix.CATEGORY)) {
                return `'${getOptionalProperty(
                    controlModel.fields[fieldKey],
                    'compoundCategorySystemName'
                )}'`;
            } else if (endsWith(this.value, FieldPostFix.ID)) {
                return `${contextEntity}.getProperty('${getOptionalProperty(
                    controlModel.fields[fieldKey],
                    'metaFieldId.systemName'
                )}').value === null ? null :
          ${contextEntity}.getProperty('${getRequiredProperty(
                    controlModel.fields[fieldKey],
                    'metaFieldId.systemName'
                )}').value.getId()`;
            } else {
                return `${contextEntity}.getProperty('${getOptionalProperty(
                    controlModel.fields[fieldKey],
                    'metaFieldId.systemName'
                )}').value`;
            }
        }
        return '';
    }

    private getFieldKey(controlModel): string {
        const key: string = split(this.value, '-', 1)[0];
        if (!controlModel.fields.hasOwnProperty(key)) {
            throw new TypeError(
                `There is no field in ${controlModel.selector} with name ${key}.`
            );
        }
        return key;
    }
}
