import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {
    AuthenticationService,
    UserData,
} from '@synisys/idm-authentication-client-js';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {DeService, MainEntity} from '@synisys/idm-de-service-client-js';
import {FormService, FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {LockData, LockingService} from '@synisys/idm-lock-service-client-js';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {catchError, map, mapTo, switchMap} from 'rxjs/operators';
import {LockGuard} from '../lock.guard';

@Injectable()
export class OnlineLockGuard extends LockGuard {
    constructor(
        private lockService: LockingService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private formService: FormService,
        private classifierService: ClassifierService,
        private deService: DeService
    ) {
        super();
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const systemName: string = route.params.categoryName;
        const formName: string = route.params.formName;
        const instanceId: number = route.params.id;
        const formId: number = Number(route.params.formId);

        return this.checkForLock(systemName, formName, instanceId, formId);
    }

    public checkForLock(
        systemName: string,
        formName: string,
        instanceId: number,
        formId: number
    ): Observable<boolean> {
        const type$: Observable<number> = this.formService.loadFormTypeId(
            formName,
            formId,
            systemName
        );

        return type$.pipe(
            switchMap((type: number) => {
                if (type === FormType.EDIT && instanceId) {
                    return this.isLocked(systemName, instanceId).pipe(
                        switchMap((isLocked: boolean) => {
                            if (isLocked) {
                                return Observable.of(false);
                            }
                            if (!isLocked && type === FormType.EDIT) {
                                return this.addLock(
                                    systemName,
                                    instanceId
                                ).pipe(mapTo(true));
                            }
                            return Observable.of(true);
                        })
                    );
                }
                return Observable.of(true);
            }),
            catchError(err => {
                console.error(err);
                this.navigateToLockErrorPage();
                return Observable.of(false);
            })
        );
    }

    private isLocked(
        systemName: string,
        instanceId: number
    ): Observable<boolean> {
        return combineLatest(
            this.lockService.getLock(systemName, instanceId),
            this.lockService.getPermanentLock(systemName, instanceId)
        ).pipe(
            switchMap(([lock, permanentLock]: [LockData, LockData]) => {
                const lockData = lock.getIsLocked()
                    ? lock
                    : permanentLock.getIsLocked()
                    ? permanentLock
                    : undefined;
                if (lockData) {
                    return this.authenticationService.getUserData().pipe(
                        map((userData: UserData) => {
                            if (
                                (lock.getIsLocked() &&
                                    userData.userId !== lock.userId) ||
                                permanentLock.getIsLocked()
                            ) {
                                this.navigateToLockErrorPage(
                                    lockData.userFullName
                                );
                                return true;
                            }
                            return false;
                        })
                    );
                }
                return Observable.of(false);
            })
        );
    }

    private addLock(systemName: string, instanceId: number): Observable<void> {
        const entity$: Observable<MainEntity> = this.deService.loadEntityByInstanceId(
            systemName,
            instanceId,
            true
        );

        return entity$.pipe(
            switchMap((entity: Entity) => {
                return this.classifierService.getEntityName(systemName, entity);
            }),
            switchMap((entityName: string) => {
                return this.lockService.addLock(
                    systemName,
                    instanceId,
                    entityName
                );
            })
        );
    }

    private navigateToLockErrorPage(userName?: string): void {
        if (userName) {
            this.router.navigateByUrl(`dynamic-de/locked;userName=${userName}`);
        }
    }
}
