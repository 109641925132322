import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DePermissionService,
    DeSerializationService,
    DeService,
    MainEntity,
    ServiceResponse,
    ServiceResponseDefault,
} from '@synisys/idm-de-service-client-js';
import {FormData, FormService} from '@synisys/idm-dynamic-layout-interpreter';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
    MetaField,
    MetaFieldId,
} from '@synisys/idm-kb-service-client-js';
import {LockData, LockingService} from '@synisys/idm-lock-service-client-js';
import {
    Validation,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {cloneDeep, isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {
    catchError,
    filter,
    first,
    map,
    mapTo,
    switchMap,
    tap,
} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {noop} from 'rxjs/util/noop';
import {DynamicLayoutType} from '../../component/dynamic-layout';
import {
    PopupData,
    PopupSubFormComponent,
} from '../../component/popup/popup-sub-form.component';
import {ActionNotificationData} from '../../concepts/action-notification-data.model';
import {ActionNotifierKey} from '../../concepts/action-notifier-key.enum';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    LayoutAction,
} from '../layout-actions';
import {defaultValueProvider} from '../utils';
import {DynamicFormDocumentsService} from './dynamic-form-documents.service';
import {DynamicFormInterface} from './dynamic-from.interface';
import {MetaFormHelperService} from './meta-form-helper.service';

// tslint:disable-next-line:no-any
type OldValues = Map<string, any>;

@Injectable()
export class DynamicFormWorkflowService {
    constructor(
        private readonly validationService: ValidationService,
        private readonly deSerializationService: DeSerializationService,
        private readonly deService: DeService,
        private readonly kbService: KbService,
        private readonly formService: FormService,
        private readonly dynamicFormDocumentsService: DynamicFormDocumentsService,
        private readonly dePermissionService: DePermissionService,
        private readonly lockService: LockingService,
        private readonly metaFormHelperService: MetaFormHelperService,
        private readonly router: Router
    ) {}

    public doWorkflowAction(
        actionId: number,
        form: DynamicFormInterface
    ): Observable<object> {
        if (isNil(actionId) || actionId === 0) {
            throw Error('actionId not given');
        }
        const result = new Subject();
        this.emptyActionDataAndCloneOther(
            form.entity,
            form.metaForm.layoutModel.category
        )
            .pipe(
                first(),
                switchMap(() => this.workflowAction(actionId, form))
            )
            .subscribe(res => {
                result.next(res);
                result.complete();
            }, console.error);
        return result;
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('onDoWorkflowAction')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    ContextArg(ContextArgEnum.ACTION_NOTIFIER),
                    ContextArg(ContextArgEnum.FORM),
                ])
                .action(
                    (
                        event: number,
                        actionNotifier: Subject<ActionNotificationData>,
                        form: DynamicFormInterface
                    ) => this.doWorkflowAction(event, form)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('doWorkflowWithoutCleaning')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    ContextArg(ContextArgEnum.FORM),
                ])
                .action((event: number, form: DynamicFormInterface) =>
                    this.workflowAction(event, form)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('openWorkflowPopup')
                .action(
                    (
                        rootForm: DynamicFormInterface,
                        categoryName: string,
                        formKey: string,
                        formId: number,
                        actionId: number
                    ) =>
                        this.openWorkflowPopup(
                            rootForm,
                            categoryName,
                            formKey,
                            formId,
                            actionId
                        )
                )
                .build()
        );
        return result;
    }

    public workflowAction(
        actionId: number,
        form: DynamicFormInterface
    ): Observable<object> {
        this.lockService
            .getLock(
                form.metaForm.layoutModel.category,
                form.entity.getInstanceId()
            )
            .pipe(
                switchMap((lockData: LockData) => {
                    if (lockData.getIsLocked()) {
                        form.showNotificationWithPlaceHolder(
                            'workflow_action_failed_locked_by_user',
                            'Error',
                            [lockData.userFullName],
                            'de_save_error_mode'
                        );
                        return Observable.empty();
                    } else {
                        return this.deService.doWorkflowAction(
                            form.metaForm.layoutModel.category,
                            form.entity,
                            actionId
                        );
                    }
                }),
                catchError(error => {
                    if (error.status === 409) {
                        form.showNotification(
                            'de_entity_already_updated',
                            'Error',
                            'de_save_error_mode'
                        );
                        form.formLoadingShow = false;
                        form.formLoadingFail = true;
                        return Observable.throw('Entity is already updated');
                    }
                    return Observable.throw(error);
                }),
                switchMap(
                    (serviceResponse: ServiceResponseDefault<MainEntity>) => {
                        const validations: Validation[] = serviceResponse
                            .getMeta()
                            .getValidations();
                        form.setMainEntityValidations(validations);
                        if (validations.length > 0) {
                            return of(serviceResponse);
                        }
                        this.dynamicFormDocumentsService.confirmDocumentsUploadAndReset(
                            form.entity,
                            form
                        );
                        // Only if false, not falsy
                        // tslint:disable-next-line:no-boolean-literal-compare
                        if (serviceResponse.hasViewPermission === false) {
                            this.router.navigateByUrl(
                                'dynamic-de/no-permission/view'
                            );
                        } else {
                            form.entity = serviceResponse.getData();
                            form.initWorkflowActions();
                        }
                        if (form.activePopup) {
                            form.activePopup.dialogRef.close(true);
                        }
                        this.emitWorkflowEvent(form, serviceResponse);
                        return this.updatePermissionsAfterWorkflowAction(
                            form
                        ).pipe(
                            mapTo(serviceResponse),
                            tap(() => form.resetForm())
                        );
                    }
                ),
                switchMap(
                    (serviceResponse: ServiceResponseDefault<MainEntity>) =>
                        this.metaFormHelperService
                            .updateRequiredFields(form.metaForm, form.entity)
                            .pipe(map(() => serviceResponse))
                ),
                tap(
                    serviceResponse =>
                        this.emitWorkflowEvent(form, serviceResponse),
                    err => {
                        if (err.status === 404) {
                            form.showNotification(
                                'de_entity_already_deleted',
                                'Error',
                                'de_save_error_mode'
                            );
                        }
                    }
                )
            )
            .subscribe(noop, console.error);
        return form.actionNotifier.pipe(
            filter(data => data.key === ActionNotifierKey.DO_WORKFLOW_ACTION),
            map(notificationData => notificationData.data as object)
        );
    }

    private emptyActionDataAndCloneOther(
        entity: Entity,
        categoryName: string
    ): Observable<OldValues> {
        return zip(
            this.kbService.getMetaFields(categoryName),
            this.kbService.getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(categoryName)
            )
        ).pipe(
            map(([metaFields, metaCategory]: [MetaField[], MetaCategory]) => {
                // tslint:disable-next-line:no-any
                const oldValues = new Map<string, any>();
                const actionFieldsNames: string[] = metaCategory
                    .getActionDataMetaFieldIds()
                    .map((actionDataField: MetaFieldId) =>
                        actionDataField.getSystemName()
                    );
                metaFields.map((metaField: MetaField) => {
                    const systemName = metaField.getSystemName();
                    oldValues.set(
                        systemName,
                        cloneDeep(entity.getProperty(systemName).value)
                    );
                    if (actionFieldsNames.indexOf(systemName) !== -1) {
                        // empty action fields
                        entity.getProperty(
                            systemName
                        ).value = defaultValueProvider(metaField.getType());
                    }
                });
                return oldValues;
            })
        );
    }

    private resetData(entity: Entity, oldValues: OldValues): void {
        oldValues.forEach(
            (value, key) => (entity.getProperty(key).value = value)
        );
    }

    private updatePermissionsAfterWorkflowAction(
        form: DynamicFormInterface
    ): Observable<void> {
        if (!form.entity || !form.entity.getInstanceId()) {
            return of(undefined);
        } else {
            return this.metaFormHelperService
                .updateDePermissions(form.metaForm, form.entity)
                .pipe(mapTo(undefined));
        }
    }

    private openWorkflowPopup(
        form: DynamicFormInterface,
        categoryName: string,
        formKey: string,
        formId: number,
        actionId: number
    ): Observable<object | number> {
        form.popupEditMode = true;
        combineLatest(
            this.formService.loadForm(formKey, formId, categoryName),
            this.emptyActionDataAndCloneOther(form.entity, categoryName)
        )
            .pipe(
                switchMap(([formData, oldValues]: [FormData, OldValues]) => {
                    return this.openPopup(
                        form,
                        categoryName,
                        formKey,
                        formId,
                        actionId,
                        formData
                    ).pipe(
                        tap(workflowPopupResult => {
                            if (!workflowPopupResult) {
                                form.actionNotifier.next(
                                    new ActionNotificationData(
                                        ActionNotifierKey.CANCEL_ACTION,
                                        undefined
                                    )
                                );
                                this.resetData(form.entity, oldValues);
                            }
                        })
                    );
                })
            )
            .subscribe(noop, console.error);
        return form.actionNotifier.pipe(
            filter(
                data =>
                    data.key === ActionNotifierKey.DO_WORKFLOW_ACTION ||
                    data.key === ActionNotifierKey.CANCEL_ACTION
            ),
            map(notificationData => notificationData.data as object)
        );
    }

    private openPopup(
        form: DynamicFormInterface,
        categoryName: string,
        formKey: string,
        formId: number,
        actionId: number,
        formData: FormData
    ): Observable<boolean> {
        const popupProperties = formData.model.content.properties.generalProperties.get(
            'workflow_popup_properties'
        );
        const width = popupProperties['width'];
        const height = popupProperties['height'];
        const title = popupProperties['titleString'];
        const dialogRef = form.dialog.open<PopupSubFormComponent, PopupData>(
            PopupSubFormComponent,
            {
                disableClose: true,
                height,
                width,
                data: {
                    form,
                    formId,
                    categoryName,
                    title,
                    entity: form.entity,
                    formKey,
                    layoutType: DynamicLayoutType.workflowForm,
                    actionId,
                },
            }
        );
        form.activePopup = dialogRef.componentInstance;
        return dialogRef.afterClosed();
    }

    private emitWorkflowEvent(
        form: DynamicFormInterface,
        serviceResponse: ServiceResponse<Entity>
    ): void {
        form.actionNotifier.next(
            new ActionNotificationData(
                ActionNotifierKey.DO_WORKFLOW_ACTION,
                serviceResponse
            )
        );
    }
}
