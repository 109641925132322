import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PageComponent} from '@synisys/idm-de-service-client-js';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';
import {RoutingHistoryService} from '../../service/local';

@Component({
    moduleId: module.id + '',
    selector: 'sis-permission',
    templateUrl: 'no-permission-page.component.html',
})
export class NoPermissionPageComponent extends PageComponent
    implements OnInit, OnDestroy {
    private readonly DYNAMIC_DE_PREFIX = '/dynamic-de/';

    /**
     * Message to interpret no-permission message.
     * @type(string)
     */
    private _warningMessage: string;

    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _routingHistoryService: RoutingHistoryService
    ) {
        super();
    }

    get warningMessage(): string {
        return this._warningMessage;
    }

    public ngOnInit(): void {
        this._route.params
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(params => {
                const categoryPermissionName = params['categoryPermissionName'];
                this.initPermissionMessage(categoryPermissionName);
            }, console.error);
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public onOkClicked(): void {
        const historyReversed = this._routingHistoryService
            .getHistory()
            .slice()
            .reverse();
        let lastPageIndex: number = historyReversed.findIndex(
            (url: string) => url.indexOf(this.DYNAMIC_DE_PREFIX) !== -1
        );
        if (lastPageIndex === -1) {
            window.history.back();
            return;
        }
        do {
            lastPageIndex++;
        } while (
            lastPageIndex < historyReversed.length &&
            historyReversed[lastPageIndex].indexOf(this.DYNAMIC_DE_PREFIX) !==
                -1
        );
        if (lastPageIndex > historyReversed.length - 1) {
            window.history.back();
            return;
        }
        // noinspection JSIgnoredPromiseFromCall
        this._router.navigateByUrl(historyReversed[lastPageIndex]);
    }

    private initPermissionMessage(categoryPermissionName: string): void {
        switch (categoryPermissionName) {
            case 'view':
                this._warningMessage = 'de_no_view_permission';
                break;
            case 'edit':
                this._warningMessage = 'de_no_edit_permission';
                break;
            case 'add':
            case 'search':
                this._warningMessage = 'de_no_add_permission';
                break;
            case 'delete':
                this._warningMessage = 'de_no_delete_permission';
                break;
            default:
                throw new TypeError(
                    `${categoryPermissionName}: not supported permission type`
                );
        }
    }
}
