import {
    KbService,
    MetaField,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {map, switchMap, tap} from 'rxjs/operators';
import {MetaFormModel} from '../model/meta-form.model';
import {of} from 'rxjs/observable/of';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    DePermission,
    DePermissionService,
} from '@synisys/idm-de-service-client-js';
import {Observable} from 'rxjs/Observable';
import {ValidationService} from '@synisys/idm-validation-calculation-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {isNil} from 'lodash';
import {Entity} from '@synisys/idm-de-core-frontend';
import {zip} from 'rxjs/observable/zip';

import {FormService, FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {FormData} from '@synisys/idm-dynamic-layout-interpreter/src/persistence/form.service';
import {Injectable} from '@angular/core';
import {PermissionType} from '@synisys/idm-authorization-client-js';

@Injectable()
export class MetaFormHelperService {
    constructor(
        private readonly _validationService: ValidationService,
        private readonly _languageService: LanguageService,
        private readonly _dePermissionService: DePermissionService,
        private readonly _kbService: KbService,
        private readonly _formService: FormService
    ) {}

    public loadFormMetaModel(
        categoryName: string,
        entity: Entity,
        formName: string,
        formId: number,
        isWithPermission: boolean
    ): Observable<MetaFormModel> {
        return this._kbService.getMetaFields(categoryName).pipe(
            switchMap((metaFields: MetaField[]) => {
                const wfStateMetaField: MetaField = metaFields.find(
                    (field: MetaField) => {
                        return field.getType() === MetaFieldType.WORKFLOW_STATE;
                    }
                );

                return zip(
                    this.loadRequiredMetaFieldIds(
                        categoryName,
                        entity,
                        wfStateMetaField
                    ),
                    this._languageService.getInputLanguages(),
                    this._formService.loadForm(formName, formId, categoryName),
                    isWithPermission ? this.loadDePermissions(categoryName, entity) : of(undefined),
                    of(metaFields)
                );
            }),
            map(
                (
                    data: [
                        MetaFieldId[],
                        Language[],
                        FormData,
                        DePermission,
                        MetaField[]
                    ]
                ) => {
                    const wfStateMetaField: MetaField = data[4].find(
                        (field: MetaField) => {
                            return (
                                field.getType() === MetaFieldType.WORKFLOW_STATE
                            );
                        }
                    );

                    return new MetaFormModel(
                        data[2].model,
                        data[0],
                        data[4],
                        data[1],
                        wfStateMetaField,
                        data[2].type,
                        formId,
                        data[3]
                    );
                }
            )
        );
    }

    public updateRequiredFields(
        metaForm: MetaFormModel,
        entity: Entity
    ): Observable<boolean> {
        return this.loadRequiredMetaFieldIds(
            metaForm.layoutModel.category,
            entity,
            metaForm.wfStateMetaField
        ).pipe(
            map(requiredFields => {
                metaForm.requiredMetaFieldIds = requiredFields;
                return true;
            })
        );
    }

    public updateDePermissions(
        metaForm: MetaFormModel,
        entity: Entity
    ): Observable<boolean> {
        return this.loadDePermissions(
            metaForm.layoutModel.category,
            entity
        ).pipe(
            map(dePermission => {
                metaForm.dePermission = dePermission;
                return true;
            })
        );
    }

    public hasPermissionFor(
        metaForm: MetaFormModel,
        permissionType: PermissionType,
        isInDrawer: boolean
    ): boolean {
        if (
            permissionType === PermissionType.EDIT &&
            metaForm.formType === FormType.VIEW &&
            isInDrawer
        ) {
            return false;
        } else {
            switch (permissionType) {
                case PermissionType.ADD:
                    return metaForm.dePermission.canCreate;
                case PermissionType.VIEW:
                    return metaForm.dePermission.canView;
                case PermissionType.EDIT:
                    return metaForm.dePermission.canEdit;
                case PermissionType.DELETE:
                    return metaForm.dePermission.canDelete;
            }
        }
    }

    private loadDePermissions(
        categoryName: string,
        entity: Entity
    ): Observable<DePermission> {
        return this._dePermissionService.getCategoryDePermissionsByInstance(
            categoryName,
            !isNil(entity.getInstanceId()) ? entity.getInstanceId() : -1
        );
    }

    private loadRequiredMetaFieldIds(
        categoryName: string,
        entity: Entity,
        wfStateMetaField: MetaField
    ): Observable<MetaFieldId[]> {
        if (isNil(wfStateMetaField)) {
            return this._validationService.getCategoryRequiredMetaFieldIds(
                categoryName
            );
        }
        const wfState = entity.getProperty<ClassifierView>(
            wfStateMetaField.getSystemName()
        );
        if (isNil(wfState.value)) {
            return this._validationService.getCategoryRequiredMetaFieldIds(
                categoryName
            );
        } else {
            return this._validationService.getCategoryRequiredMetaFieldIds(
                categoryName,
                wfState.value.getId()
            );
        }
    }
}
