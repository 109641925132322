/**
 * @author Vahagn Lazyan.
 * @since 2.4.0
 */
import {Component} from '@angular/core';

import {PageComponent} from '@synisys/idm-de-service-client-js';
import './successfully-deleted-page.component.scss';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-successfully-deleted-page',
             templateUrl: 'successfully-deleted-page.component.html',
           })
export class SuccessfullyDeletedPageComponent extends PageComponent {
}
