import {
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {MainEntity, SubEntity} from '@synisys/idm-de-service-client-js';
import {Observable} from 'rxjs/Observable';
import {Entity, EntityDefault} from '@synisys/idm-de-core-frontend';
import {Injectable, OnDestroy} from '@angular/core';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    FieldArg,
    LayoutAction,
    StateArg,
} from '../layout-actions';
import {isNil} from 'lodash';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {AbstractDestructionSubject} from '../../component/abstract-destruction-subject';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class DynamicFormClassifiersService extends AbstractDestructionSubject {
    public classifierReplayItems: Map<
        string,
        ReplaySubject<ClassifierView[]>
    > = new Map();
    public classifierNameReplayItems: Map<
        string,
        ReplaySubject<string>
    > = new Map();
    private emptyArray: ClassifierView[] = [];

    constructor(
        private readonly classifierService: ClassifierService,
        private readonly currentLanguageProvider: CurrentLanguageProvider
    ) {
        super();
        this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((lang: Language) => {
                this.resetCache();
            }, console.error);
    }

    public onClassifierChange(
        event: number,
        systemName: string,
        classifierKey: string,
        entity: MainEntity
    ): void {
        const selectedClassifierId: number = event;
        if (selectedClassifierId && selectedClassifierId >= 0) {
            this.classifierReplayItems
                .get(classifierKey)
                .subscribe((classifiers: ClassifierView[]) => {
                    classifiers.map((classifier: ClassifierView) => {
                        if (classifier.getId() === selectedClassifierId) {
                            entity.getProperty(systemName).value = classifier;
                        }
                    });
                }, console.error);
        } else {
            entity.getProperty(systemName).value = null;
        }
    }

    public onSubEntityClassifierChange(
        event: number,
        systemName: string,
        classifierKey: string,
        subEntity: SubEntity
    ): void {
        const selectedClassifierId: number = event;
        if (selectedClassifierId) {
            this.classifierReplayItems
                .get(classifierKey)
                .subscribe((classifiers: ClassifierView[]) => {
                    classifiers.map((classifier: ClassifierView) => {
                        if (classifier.getId() === selectedClassifierId) {
                            subEntity.getProperty(
                                systemName
                            ).value = classifier;
                        }
                    });
                }, console.error);
        }
    }

    public resetClassifiers(systemNames: string[], entity: MainEntity) {
        systemNames.forEach((item: string) => {
            entity.getProperty(item).value = null;
        });
    }

    public onChildClassifierChange(
        event: number,
        systemName: string,
        classifierKey: string,
        parent: ClassifierView,
        entity: MainEntity
    ): void {
        const selectedClassifierId: number = event;
        if (selectedClassifierId) {
            const itemKey: string = classifierKey + '_' + parent.getId();
            this.classifierReplayItems
                .get(itemKey)
                .subscribe((classifiers: ClassifierView[]) => {
                    classifiers.map((classifier: ClassifierView) => {
                        if (classifier.getId() === selectedClassifierId) {
                            entity.getProperty(systemName).value = classifier;
                        }
                    });
                }, console.error);
        } else {
            entity.getProperty(systemName).value = null;
        }
    }

    public onSubEntityChildClassifierChange(
        event: number,
        systemName: string,
        classifierKey: string,
        parent: ClassifierView,
        subEntity: SubEntity,
        entity: MainEntity
    ): void {
        const selectedClassifierId: number = event;
        if (selectedClassifierId) {
            const itemKey: string = classifierKey + '_' + parent.getId();
            this.classifierReplayItems
                .get(itemKey)
                .subscribe((classifiers: ClassifierView[]) => {
                    classifiers.map((classifier: ClassifierView) => {
                        if (classifier.getId() === selectedClassifierId) {
                            subEntity.getProperty(
                                systemName
                            ).value = classifier;
                        }
                    });
                }, console.error);
        } else {
            entity.getProperty(systemName).value = null;
        }
    }

    public loadClassifiers(systemName: string): Observable<ClassifierView[]> {
        if (!this.classifierReplayItems.has(systemName)) {
            const replay: ReplaySubject<ClassifierView[]> = new ReplaySubject(
                1
            );
            this.classifierReplayItems.set(systemName, replay);

            this.classifierService
                .loadClassifiersView(systemName)
                .subscribe((classifiers: ClassifierView[]) => {
                    replay.next(classifiers);
                }, console.error);
        }

        return this.classifierReplayItems.get(systemName);
    }

    public loadClassifiersByParent(
        systemName: string,
        parent: ClassifierView
    ): Observable<ClassifierView[]> {
        if (parent !== null && parent.getId() !== null) {
            const itemKey: string = systemName + '_' + parent.getId();
            if (!this.classifierReplayItems.has(itemKey)) {
                const replay: ReplaySubject<ClassifierView[]> = new ReplaySubject(
                    1
                );
                this.classifierReplayItems.set(itemKey, replay);
                this.classifierService
                    .loadClassifiersViewByParent(systemName, parent.getId())
                    .subscribe((classifiers: ClassifierView[]) => {
                        replay.next(classifiers);
                    }, console.error);
            }
            return this.classifierReplayItems.get(itemKey);
        }
        return Observable.of(this.emptyArray);
    }

    public onMultiSelectComboItemsChanged(
        selectedIds: number[],
        systemName: string,
        classifierKey: string,
        entity: MainEntity
    ): void {
        let selectedItems: EntityDefault[];
        selectedItems = entity.getProperty<EntityDefault[]>(systemName).value;

        this.classifierReplayItems
            .get(classifierKey)
            .subscribe((classifiers: ClassifierView[]) => {
                classifiers.map((classifier: ClassifierView) => {
                    if (
                        this.checkIfIdInArray(classifier.getId(), selectedIds)
                    ) {
                        selectedItems.push(classifier);
                    } else {
                        for (let i = 0; i < selectedItems.length; i++) {
                            if (
                                classifier.getId() === selectedItems[i].getId()
                            ) {
                                selectedItems.splice(i, 1);
                            }
                        }
                    }
                });
            }, console.error);
    }

    public onMultiSelectItemsChanged(
        selectedItem: EntityDefault,
        systemName: string,
        entity: MainEntity,
        subEntity?: SubEntity
    ): void {
        let selectedItems: EntityDefault[];
        if (subEntity) {
            selectedItems = subEntity.getProperty<EntityDefault[]>(systemName)
                .value;
        } else {
            selectedItems = entity.getProperty<EntityDefault[]>(systemName)
                .value;
        }
        const isChecked =
            selectedItems.find(
                item => item.getId() === selectedItem.getId()
            ) === undefined;
        if (isChecked) {
            selectedItems.push(selectedItem);
        } else {
            for (let i = 0; i < selectedItems.length; i++) {
                if (selectedItem.getId() === selectedItems[i].getId()) {
                    selectedItems.splice(i, 1);
                }
            }
        }
    }

    public simpleChange(
        mainEntity: MainEntity,
        systemName: string,
        selectedValue: any
    ) {
        mainEntity.getProperty(systemName).value = selectedValue;
    }

    public getClassifierName(
        classifier: Entity,
        category: string
    ): Observable<string> {
        const categoryKey =
            isNil(category) || isNil(classifier)
                ? ''
                : `${category}_${classifier.getId()}`;
        if (this.classifierNameReplayItems.has(categoryKey)) {
            return this.classifierNameReplayItems.get(categoryKey);
        } else {
            const replaySubject = new ReplaySubject<string>(1);
            if (categoryKey === '') {
                this.classifierNameReplayItems.set('', replaySubject);
                replaySubject.next('');
                return replaySubject;
            } else if (classifier instanceof ClassifierView) {
                this.classifierNameReplayItems.set(categoryKey, replaySubject);
                replaySubject.next(classifier.getName());
                return replaySubject;
            } else {
                this.classifierNameReplayItems.set(categoryKey, replaySubject);
                this.classifierService
                    .getEntityName(category, classifier)
                    .subscribe(
                        value => replaySubject.next(value),
                        console.error
                    );
                return replaySubject;
            }
        }
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('onChildClassifierChange')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    FieldArg('field'),
                    FieldArg('field-category'),
                    FieldArg('field-parent'),
                    ContextArg(ContextArgEnum.ENTITY),
                ])
                .action(
                    (
                        event: number,
                        systemName: string,
                        classifierKey: string,
                        parent: ClassifierView,
                        entity: MainEntity
                    ) =>
                        this.onChildClassifierChange(
                            event,
                            systemName,
                            classifierKey,
                            parent,
                            entity
                        )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('onClassifierChange')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    FieldArg('field-name'),
                    FieldArg('field-category'),
                    ContextArg(ContextArgEnum.CONTEXT_ENTITY),
                ])
                .action(
                    (
                        event: number,
                        systemName: string,
                        classifierKey: string,
                        entity: MainEntity
                    ) =>
                        this.onClassifierChange(
                            event,
                            systemName,
                            classifierKey,
                            entity
                        )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('resetClassifiers')
                .params([
                    StateArg('systemNames'),
                    ContextArg(ContextArgEnum.ENTITY),
                ])
                .action((systemNames: string[], entity: MainEntity) =>
                    this.resetClassifiers(systemNames, entity)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('loadClassifiers')
                .params([FieldArg('field-category')])
                .action((systemName: string) =>
                    this.loadClassifiers(systemName)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('simpleChange')
                .params([
                    ContextArg(ContextArgEnum.CONTEXT_ENTITY),
                    FieldArg('field-name'),
                    ContextArg(ContextArgEnum.EVENT),
                ])
                .action(
                    (
                        mainEntity: MainEntity,
                        systemName: string,
                        selectedValue: any
                    ) =>
                        this.simpleChange(mainEntity, systemName, selectedValue)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('loadClassifiersByParent')
                // .params([ContextArg(ContextArgEnum.ENTITY), FieldArg('field-name'), ContextArg(ContextArgEnum.EVENT)])
                .action((systemName: string, parent: ClassifierView) =>
                    this.loadClassifiersByParent(systemName, parent)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('onMultiSelectItemsChanged')
                .params([
                    ContextArg(ContextArgEnum.EVENT),
                    FieldArg('field-name'),
                    ContextArg(ContextArgEnum.ENTITY),
                    ContextArg(ContextArgEnum.ITEM),
                ])
                .action(
                    (
                        checkedItem: EntityDefault,
                        systemName: string,
                        mainEntity: MainEntity,
                        subEntity: SubEntity
                    ) =>
                        this.onMultiSelectItemsChanged(
                            checkedItem,
                            systemName,
                            mainEntity,
                            subEntity
                        )
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('getClassifierName')
                .params([FieldArg('field'), FieldArg('field-category')])
                .action((classifier: Entity, category: string) =>
                    this.getClassifierName(classifier, category)
                )
                .build()
        );

        return result;
    }

    private checkIfIdInArray(id: number, idList: number[]): boolean {
        for (const toCheck of idList) {
            if (toCheck === id) {
                return true;
            }
        }
        return false;
    }

    private resetCache() {
        this.classifierNameReplayItems = new Map();
        this.classifierReplayItems = new Map();
    }
}
