import {DynamicArgument} from './dynamic-argument.type';

export class ActionBuilder {

  /* tslint:disable:max-classes-per-file*/
  public static layoutActionImpl = class  implements LayoutAction {
    public metaArgs: DynamicArgument[];
    public func: Function;
    public name: string;

    constructor(name: string, metaArgs: DynamicArgument[], func: Function) {
      this.name = name;
      this.metaArgs = metaArgs;
      this.func = func;
    }
  };

  /* tslint:disable:max-classes-per-file*/

  private _metaArgs: DynamicArgument[];
  private _func: Function;
  private _name: string;

  public params(metaArgs: DynamicArgument[]): ActionBuilder {
    this._metaArgs = metaArgs;
    return this;
  }

  public action(func: Function) {
    this._func = func;
    return this;
  }

  public name(name: string) {
    this._name = name;
    return this;
  }

  public build(): LayoutAction {
    return new ActionBuilder.layoutActionImpl(this._name, this._metaArgs, this._func);
  }

}

export interface LayoutAction {
  metaArgs: DynamicArgument[];
  func: Function;
  name: string;
}
