import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {DePermission} from '@synisys/idm-de-service-client-js';
import {
    FormLayoutModel,
    FormType,
} from '@synisys/idm-dynamic-layout-interpreter';
import {MetaField, MetaFieldId} from '@synisys/idm-kb-service-client-js';

export class MetaFormModel {
    constructor(
        public readonly layoutModel: FormLayoutModel,
        public requiredMetaFieldIds: MetaFieldId[],
        public readonly metaFields: MetaField[],
        public readonly languages: Language[],
        public readonly wfStateMetaField: MetaField,
        public readonly formType: FormType,
        public readonly formId: number,
        public dePermission?: DePermission
    ) {}
}
