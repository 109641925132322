/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {Observable} from "rxjs/Observable";

export abstract class DePermissionGuard implements CanActivate {

  public abstract canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;
}
