import {MainEntity} from '@synisys/idm-de-service-client-js';
import {Params} from '@angular/router';
import {Observable} from 'rxjs/Observable';

export abstract class DefaultModelDecoratorProvider {
    public abstract decorate(
        category: string,
        formId: number,
        params: Params,
        queryParams: Params,
        transientFields: object,
        entity: MainEntity
    ): Observable<MainEntity>;
}
