import {Injectable, Optional} from '@angular/core';

import {concat, remove, some} from 'lodash';

import {DynamicFormClassifiersService} from './dynamic-form-classifiers.service';
import {DynamicFormPopupsService} from './dynamic-form-popups.service';
import {DynamicFormMainDeService} from './dynamic-form-main-de.service';
import {DynamicFormDocumentsService} from './dynamic-form-documents.service';
import {DynamicFormNavigationsService} from './dynamic-form-navigations.service';
import {DynamicFormUtilitiesService} from './dynamic-form-utilities.service';
import {AdditionalActionsProvider} from '../api';
import {LayoutAction} from '../layout-actions';
import {DynamicFormWorkflowService} from './dynamic-form-workflow.service';
import {DynamicFormKbService} from './dynamic-form-kb.service';

@Injectable()
export class ActionResolverService {
    constructor(
        private readonly dynamicFormClassifiersService: DynamicFormClassifiersService,
        private readonly dynamicFormPopupsService: DynamicFormPopupsService,
        private readonly dynamicFormMainDeService: DynamicFormMainDeService,
        private readonly dynamicFormDocumentsService: DynamicFormDocumentsService,
        private readonly dynamicFormNavigationService: DynamicFormNavigationsService,
        private readonly dynamicFormUtilitiesService: DynamicFormUtilitiesService,
        private readonly dynamicFormWorkflowService: DynamicFormWorkflowService,
        private readonly dynamicFormKbService: DynamicFormKbService,
        @Optional()
        private readonly additionalActionsProvider: AdditionalActionsProvider
    ) {}

    public getAction(): LayoutAction[] {
        let result: LayoutAction[] = [];
        result = concat(
            result,
            this.dynamicFormClassifiersService.getActions(),
            this.dynamicFormPopupsService.getActions(),
            this.dynamicFormDocumentsService.getActions(),
            this.dynamicFormMainDeService.getActions(),
            this.dynamicFormNavigationService.getActions(),
            this.dynamicFormUtilitiesService.getActions(),
            this.dynamicFormWorkflowService.getActions(),
            this.dynamicFormKbService.getActions()
        );

        if (this.additionalActionsProvider) {
            const additionalActions = this.additionalActionsProvider.getActions();
            remove(result, (action: LayoutAction) => {
                // overwrites
                return some(
                    additionalActions,
                    (value: LayoutAction) => value.name === action.name
                );
            });
            result = concat(result, additionalActions);
        }

        return result;
    }
}
