import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {KbService, MetaFieldId} from '@synisys/idm-kb-service-client-js';
import {ActionBuilder, FieldArg, LayoutAction} from '../layout-actions';

@Injectable()
export class DynamicFormKbService {
    constructor(private readonly kbService: KbService) {}

    public getNameMetaFieldsSystemName(category: string): Observable<string[]> {
        return this.kbService.getNameMetaFieldIds(category).pipe(
            map((fields: MetaFieldId[]) => {
                return fields.map(field => field.getSystemName());
            })
        );
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('nameMetaFields')
                .params([FieldArg('field-category')])
                .action((systemName: string) =>
                    this.getNameMetaFieldsSystemName(systemName)
                )
                .build()
        );

        return result;
    }
}
