import {ContextArgEnum, DynamicArgument} from './dynamic-argument.type';
import {ControlModel} from '@synisys/idm-dynamic-layout-interpreter';
import {ContextEntity} from '../../component';

export class ContextArgument implements DynamicArgument {
    private value: ContextArgEnum;

    constructor(arg: ContextArgEnum) {
        this.value = arg;
    }

    public resolve(
        controller: ControlModel,
        contextEntity: ContextEntity | undefined
    ): string {
        return this.value;
    }
}
