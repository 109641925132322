import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map, switchMap, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {ConformationDialogComponent} from './conformation-dialog.component';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router/src/router_state';
import {isNil} from 'lodash';
import {AuthenticationService} from '@synisys/idm-authentication-client-js';

export interface EntityMonitoringInterface {
    deContainer: {
        form: {
            noChanges: () => Observable<boolean>;
            resetEntity: () => void;
            cleanEntity: () => void;
            isTabJustChanged: () => boolean;
            resetTabJustChangedFlag: () => void;
        };
    };
}

@Injectable()
export class WarningOnLeaveGuard
    implements CanDeactivate<EntityMonitoringInterface> {
    constructor(
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {}

    public canDeactivate(
        monitoring: EntityMonitoringInterface,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> {
        if (monitoring.deContainer.form.isTabJustChanged()) {
            monitoring.deContainer.form.resetTabJustChangedFlag();
            return Observable.of(true);
        }
        const isAtSameUrl = this.isAtSameUrl(currentState, nextState);

        monitoring.deContainer.form.resetTabJustChangedFlag();
        return monitoring.deContainer.form
            ? monitoring.deContainer.form
                  .noChanges()
                  .first()
                  .pipe(
                      switchMap((noChanges: boolean) => {
                          if (!noChanges) {
                              return this.authenticationService
                                  .isLoggedOn()
                                  .pipe(
                                      switchMap(isLogged => {
                                          if (isLogged) {
                                              const dialogRef = this.dialog.open(
                                                  ConformationDialogComponent
                                              );
                                              return dialogRef
                                                  .afterClosed()
                                                  .pipe(
                                                      tap(result => {
                                                          if (
                                                              isAtSameUrl &&
                                                              result
                                                          ) {
                                                              monitoring.deContainer.form.resetEntity();
                                                          }
                                                      })
                                                  );
                                          } else {
                                              return Observable.of(true);
                                          }
                                      })
                                  );
                          } else if (isAtSameUrl) {
                              return Observable.of(true).pipe(
                                  tap(_ => {
                                      monitoring.deContainer.form.cleanEntity();
                                  })
                              );
                          }
                          return Observable.of(true);
                      })
                  )
            : Observable.of(true);
    }

    private isAtSameUrl(
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean {
        if (!isNil(nextState)) {
            return (
                nextState.url.split('#')[0] === currentState.url.split('#')[0]
            );
        }

        return false;
    }
}
