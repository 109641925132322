import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PermissionType} from '@synisys/idm-authorization-client-js';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {EntityComparisonHelper} from '@synisys/idm-de-core-frontend/app/shared/impl/helper/entity-comparison-helper';
import {
    DePermissionService,
    DeService,
    MainEntity,
    NotificationStatus,
    ServiceResponse,
    ServiceResponseDefault,
} from '@synisys/idm-de-service-client-js';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LockingService} from '@synisys/idm-lock-service-client-js';
import {
    Validation,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {FlashMessagesService} from 'angular2-flash-messages';
import {Observable} from 'rxjs/Observable';
import {noop} from 'rxjs/util/noop';
import {ActionNotificationData, ActionNotifierKey} from '../../concepts';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    LayoutAction,
    StateArg,
} from '../layout-actions';
import {DynamicFormDocumentsService} from './dynamic-form-documents.service';
import {DynamicFormNavigationsService} from './dynamic-form-navigations.service';
import {DynamicFormInterface} from './dynamic-from.interface';
import {RoutingHistoryService} from './routing-history.service';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {ConformationDialogComponent} from '../guards/impl/conformation-dialog.component';
import {MatDialog} from '@angular/material';
import {FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {MetaFormHelperService} from './meta-form-helper.service';
import {isNil} from 'lodash';

@Injectable()
export class DynamicFormMainDeService {
    private entityComparisionHelper: EntityComparisonHelper;

    constructor(
        private deService: DeService,
        private validationService: ValidationService,
        private flashMessagesService: FlashMessagesService,
        private kbService: KbService,
        private dynamicFormNavigationsService: DynamicFormNavigationsService,
        private dynamicFormDocumentsService: DynamicFormDocumentsService,
        private dePermissionService: DePermissionService,
        private router: Router,
        private lockService: LockingService,
        private classifierService: ClassifierService,
        private location: Location,
        private routingHistoryService: RoutingHistoryService,
        private dialog: MatDialog,
        private metaFormHelperService: MetaFormHelperService
    ) {
        this.entityComparisionHelper = new EntityComparisonHelper(kbService);
    }

    public onSave(form: DynamicFormInterface): Observable<object> {
        form.formLoadingShow = true;
        console.log('saving ...');

        this.compareEntities(form)
            .pipe(
                switchMap((isEqual: boolean) => {
                    if (isEqual) {
                        this.showNotificationMessage(
                            NotificationStatus.NO_MODIFICATION,
                            form
                        );
                        this.saveOnNoModificationCase(false, form);
                        return Observable.empty();
                    }
                    return this.save(form);
                }),
                catchError(error => this.saveActionErrorHandler(form, error)),
                switchMap((serviceResponse: ServiceResponse<MainEntity>) => {
                    form.formLoadingShow = false;
                    return this.afterSaveProcessing(
                        serviceResponse,
                        false,
                        form
                    ).map(() => serviceResponse);
                }),
                map((serviceResponse: ServiceResponse<MainEntity>) => {
                    this.notifyOnAction(
                        form,
                        ActionNotifierKey.SAVE_ACTION,
                        serviceResponse
                    );

                    if (form.inDrawer) {
                        form.actions.notifyParentForDrawerSave(serviceResponse);
                    }
                    return serviceResponse;
                }),
                first()
            )
            .subscribe(noop, console.log);

        return form.actionNotifier
            .filter(data => data.key === ActionNotifierKey.SAVE_ACTION)
            .map(notificationData => notificationData.data as object);
    }

    public onSaveAndClose(
        form: DynamicFormInterface,
        whereToGo?: string
    ): Observable<object> {
        form.formLoadingShow = true;
        console.log('saving ...');

        this.compareEntities(form)
            .pipe(
                switchMap((isEqual: boolean) => {
                    if (isEqual) {
                        this.showNotificationMessage(
                            NotificationStatus.NO_MODIFICATION,
                            form
                        );
                        this.saveOnNoModificationCase(true, form, whereToGo);
                        return Observable.empty();
                    }
                    return this.save(form);
                }),
                catchError(error => this.saveActionErrorHandler(form, error)),
                switchMap((serviceResponse: ServiceResponse<MainEntity>) => {
                    form.formLoadingShow = false;
                    return this.afterSaveProcessing(
                        serviceResponse,
                        true,
                        form,
                        whereToGo
                    ).map(() => serviceResponse);
                }),
                map((serviceResponse: ServiceResponse<MainEntity>) => {
                    this.notifyOnAction(
                        form,
                        ActionNotifierKey.SAVE_ACTION,
                        serviceResponse
                    );

                    this.notifyOnAction(
                        form,
                        ActionNotifierKey.CLOSE_ACTION,
                        undefined
                    );

                    if (form.inDrawer) {
                        form.actions.notifyParentForDrawerSave(serviceResponse);
                    }
                    return serviceResponse;
                }),
                first()
            )
            .subscribe(noop, console.log);

        return form.actionNotifier
            .filter(data => data.key === ActionNotifierKey.SAVE_ACTION)
            .map(notificationData => notificationData.data as object);
    }

    public onBack(form: DynamicFormInterface, whereToGo: string): void {
        this.deService
            .loadEntityByInstanceId(
                form.metaForm.layoutModel.category,
                form.entity.getInstanceId()
            )
            .subscribe(
                () => {
                    form.removeLock().subscribe(noop, console.error);
                    this.router.navigate([whereToGo]);
                },
                err => {
                    if (err.status === 404) {
                        form.showNotification(
                            'de_entity_already_deleted',
                            'Error',
                            'de_save_error_mode'
                        );
                    }
                }
            );
    }

    public delete(form: DynamicFormInterface): void {
        if (
            this.metaFormHelperService.hasPermissionFor(
                form.metaForm,
                PermissionType.DELETE,
                form.inDrawer
            )
        ) {
            this.deService
                .deleteById(
                    form.metaForm.layoutModel.category,
                    form.entity.getId()
                )
                .subscribe(
                    () => {
                        this.router.navigateByUrl(
                            'dynamic-de/successfully-deleted'
                        );
                    },
                    error => {
                        if (error.status === 404) {
                            form.showNotification(
                                'de_entity_already_deleted',
                                'Error',
                                'de_save_error_mode'
                            );
                        }
                    }
                );
        } else {
            this.router.navigateByUrl('dynamic-de/no-permission/delete');
        }
    }

    public onCancel(form: DynamicFormInterface, whereToGo: string): void {
        this.checkForDrawerClose(form)
            .pipe(
                switchMap((isEqual: boolean) => {
                    if (!isEqual) {
                        const dialogRef = form.dialog.open(
                            ConformationDialogComponent
                        );
                        return dialogRef.afterClosed();
                    }
                    return Observable.of(true);
                }),
                switchMap((proceedToClosing: boolean) => {
                    if (proceedToClosing) {
                        if (!form.entity.getInstanceId()) {
                            if (!form.inDrawer) {
                                this.locationBack();
                                this.notifyOnAction(
                                    form,
                                    ActionNotifierKey.CLOSE_ACTION,
                                    undefined
                                );
                                return Observable.empty();
                            }
                            return Observable.of(true);
                        }
                        return this.deService
                            .loadEntityByInstanceId(
                                form.metaForm.layoutModel.category,
                                form.entity.getInstanceId()
                            )
                            .pipe(switchMap(() => form.removeLock()));
                    }
                    return Observable.empty();
                })
            )
            .subscribe(
                () => {
                    this.notifyOnAction(
                        form,
                        ActionNotifierKey.CLOSE_ACTION,
                        undefined
                    );
                    this.close(form, whereToGo);
                },
                err => {
                    if (err.status === 404) {
                        form.showNotification(
                            'de_entity_already_deleted',
                            'Error',
                            'de_save_error_mode'
                        );
                    }
                }
            );
    }

    public getActions(): LayoutAction[] {
        const result: LayoutAction[] = [];

        result.push(
            new ActionBuilder()
                .name('onSave')
                .params([ContextArg(ContextArgEnum.FORM)])
                .action((form: DynamicFormInterface) => this.onSave(form))
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('onSaveAndClose')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('whereToGo'),
                ])
                .action((form: DynamicFormInterface, whereToGo: string) =>
                    this.onSaveAndClose(form, whereToGo)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('onBack')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('whereToGo'),
                ])
                .action((form: DynamicFormInterface, whereToGo: string) =>
                    this.onBack(form, whereToGo)
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('delete')
                .params([ContextArg(ContextArgEnum.FORM)])
                .action((form: DynamicFormInterface) => this.delete(form))
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('onCancel')
                .params([
                    ContextArg(ContextArgEnum.FORM),
                    StateArg('whereToGo'),
                ])
                .action((form: DynamicFormInterface, whereToGo: string) =>
                    this.onCancel(form, whereToGo)
                )
                .build()
        );

        result.push(
            new ActionBuilder()
                .name('hasEditPermission')
                .params([ContextArg(ContextArgEnum.FORM)])
                .action((form: DynamicFormInterface) =>
                    this.metaFormHelperService.hasPermissionFor(
                        form.metaForm,
                        PermissionType.EDIT,
                        form.inDrawer
                    )
                )
                .build()
        );
        result.push(
            new ActionBuilder()
                .name('hasDeletePermission')
                .params([ContextArg(ContextArgEnum.FORM)])
                .action((form: DynamicFormInterface) =>
                    this.metaFormHelperService.hasPermissionFor(
                        form.metaForm,
                        PermissionType.DELETE,
                        form.inDrawer
                    )
                )
                .build()
        );

        return result;
    }

    private checkForDrawerClose(
        form: DynamicFormInterface
    ): Observable<boolean> {
        if (form.inDrawer) {
            return this.compareEntities(form);
        }
        return Observable.of(true);
    }

    private locationBack(): void {
        const history = this.routingHistoryService.getHistory();
        if (!history) {
            this.location.back();
            return;
        }

        const firstPageIndex = history.lastIndexOf(
            this.router.url.replace(/#[0-9]*$/, '')
        );
        if (firstPageIndex < 1) {
            this.location.back();
            return;
        }
        this.router.navigateByUrl(history[firstPageIndex - 1]);
    }

    private afterSaveProcessing(
        serviceResponse: ServiceResponse<MainEntity>,
        isWithCloseAction: boolean,
        form: DynamicFormInterface,
        whereToGo?: string
    ): Observable<boolean> {
        const validations: Validation[] = serviceResponse
            .getMeta()
            .getValidations();

        const newlyCreated = !form.entity.getInstanceId();

        if (validations.length > 0) {
            this.validationsTriggered(form, validations);
        } else {
            this.successSaveResponseHandler(serviceResponse, form);
            return this.metaFormHelperService
                .updateDePermissions(form.metaForm, form.entity)
                .pipe(
                    switchMap(() => {
                        if (isWithCloseAction) {
                            if (
                                !isNil(form.metaForm.dePermission) &&
                                form.metaForm.dePermission.canView
                            ) {
                                this.close(form, whereToGo);
                                return form.removeLock();
                            } else {
                                this.router.navigateByUrl(
                                    'dynamic-de/no-permission/view'
                                );
                            }
                        }
                        if (newlyCreated) {
                            return this.addLockAfterSave(form);
                        }
                        return Observable.of(true);
                    })
                );
        }

        return Observable.of(false);
    }

    private validationsTriggered(
        form: DynamicFormInterface,
        validations: Validation[]
    ): void {
        form.setMainEntityValidations(validations);
        form.formLoadingFail = true;
        this.showNotificationMessage(NotificationStatus.FAILURE, form);
    }

    private successSaveResponseHandler(
        serviceResponse: ServiceResponse<MainEntity>,
        form: DynamicFormInterface
    ): void {
        form.entity = serviceResponse.getData();
        form.oldEntity = form.entity.clone();
        form.formLoadingSuccess = true;

        form.setMainEntityValidations([]);

        this.dynamicFormDocumentsService.confirmDocumentsUploadAndReset(
            form.entity,
            form
        );
        this.showNotificationMessage(NotificationStatus.SUCCESS, form);
        console.log('Saved with ID : ' + form.entity.getId());

        setTimeout(() => {
            form.formLoadingFail = false;
            form.formLoadingSuccess = false;
        }, 100);
    }

    private addLockAfterSave(form: DynamicFormInterface): Observable<boolean> {
        return this.classifierService
            .getEntityName(form.metaForm.layoutModel.category, form.entity)
            .switchMap((name: string) =>
                this.lockService.addLock(
                    form.metaForm.layoutModel.category,
                    form.entity.getInstanceId(),
                    name
                )
            );
    }

    private save(
        form: DynamicFormInterface
    ): Observable<ServiceResponse<MainEntity>> {
        if (form.entity.getId() && form.entity.getId() >= 0) {
            return this.deService.update(
                form.metaForm.layoutModel.category,
                form.entity
            );
        } else {
            return this.deService.add(
                form.metaForm.layoutModel.category,
                form.entity
            );
        }
    }

    private compareEntities(form: DynamicFormInterface): Observable<boolean> {
        if (form.entity.getId()) {
            return Observable.fromPromise(
                this.entityComparisionHelper.equals(
                    form.metaForm.layoutModel.category,
                    form.entity,
                    form.oldEntity
                )
            ).first();
        } else {
            return Observable.of(false);
        }
    }

    private saveOnNoModificationCase(
        isWithCloseAction: boolean,
        form: DynamicFormInterface,
        whereToGo?: string
    ): void {
        const response = new ServiceResponseDefault<Entity>();
        response.setData(form.entity);
        form.setMainEntityValidations([]);
        if (form.inDrawer) {
            form.actions.notifyParentForDrawerSave(response);
        }
        form.formLoadingShow = false;
        form.formLoadingSuccess = true;
        form.formLoadingSuccess = false;

        if (isWithCloseAction) {
            this.close(form, whereToGo);
            form.removeLock().subscribe(() => {
                this.notifyOnAction(
                    form,
                    ActionNotifierKey.SAVE_ACTION,
                    response
                );
                if (isWithCloseAction) {
                    this.notifyOnAction(
                        form,
                        ActionNotifierKey.CLOSE_ACTION,
                        undefined
                    );
                }
            }, console.error);
        } else {
            this.notifyOnAction(form, ActionNotifierKey.SAVE_ACTION, response);
        }
    }

    private notifyOnAction(
        form: DynamicFormInterface,
        key: ActionNotifierKey,
        data: object | number
    ): void {
        form.actionNotifier.next(new ActionNotificationData(key, data));
    }

    private showNotificationMessage(
        status: NotificationStatus,
        form: DynamicFormInterface
    ): void {
        if (status === NotificationStatus.FAILURE) {
            form.showNotification('de_save_validation_errors', 'Error', 'de_save_error_mode');
        } else if (status === NotificationStatus.SUCCESS) {
            form.showNotification('de_save_success', 'Success', 'de_save_success_mode');
        } else if (status === NotificationStatus.NO_MODIFICATION) {
            form.showNotification('de_save_no_modification', 'Warning', 'de_save_warning_mode');
        } else {
            throw new TypeError(
                `Notification status ${status} is not supported for messages.`
            );
        }
    }

    private saveActionErrorHandler(
        form: DynamicFormInterface,
        err
    ): Observable<any> {
        if (err.status === 403) {
            if (
                form.metaForm.formType === FormType.EDIT &&
                form.entity.getInstanceId() === null
            ) {
                form.showNotification('de_no_create_permission', 'Error', 'de_save_error_mode');
                form.formLoadingShow = false;
                form.formLoadingFail = true;
                return Observable.throw('No permission to create entity');
            }
        } else if (err.status === 404) {
            form.showNotification('de_entity_already_deleted', 'Error', 'de_save_error_mode');
            form.formLoadingShow = false;
            form.formLoadingFail = true;
            return Observable.throw('Entity is already deleted');
        } else if (err.status === 409) {
            form.showNotification('de_entity_already_updated', 'Error', 'de_save_error_mode');
            form.formLoadingShow = false;
            form.formLoadingFail = true;
            return Observable.throw('Entity is already updated');
        } else {
            form.actionNotifier.error(err);
            form.formLoadingShow = false;
            form.formLoadingFail = true;
            return Observable.throw(err);
        }
    }

    private close(form: DynamicFormInterface, whereToGo?: string): void {
        if (form.inDrawer) {
            form.actions.toggleFromEntityPopup();
        } else {
            whereToGo
                ? this.router.navigate([whereToGo])
                : this.dynamicFormNavigationsService.toDefaultViewForm(
                      form,
                      form.metaForm.layoutModel.category,
                      form.entity
                  );
        }
    }
}
