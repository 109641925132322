import {ControlModel} from '@synisys/idm-dynamic-layout-interpreter';
import {FieldArgument} from './field-argument';
import {StateArgument} from './state-argument';
import {ContextArgument} from './context-argument';
import {ContextEntity} from '../../component';

// export type DynamicArgumentType = FieldArgClass | StateArgClass | ContextArgClass;

export interface DynamicArgument {
    resolve(
        controller: ControlModel,
        contextEntity: ContextEntity | undefined
    ): string;
}

export enum ContextArgEnum {
    EVENT = '$event',
    FORM = 'form',
    ENTITY = 'entity',
    ACTION_NOTIFIER = 'actionNotifier',
    CATEGORY = 'category',
    ENTITY_ID = 'entityId',
    LANGUAGES = 'languages',
    CURRENT_LANGUAGE_ID = 'currentLanguageId',
    VALIDATIONS = 'validations',
    QUERY_PARAMS = 'queryParams',
    ITEM = 'item',
    ACTIONS = 'actions',
    CONTEXT_ENTITY = 'contextEntity',
    WORKFLOW_ACTIONS = 'workflowActions',
    TRANSIENT_FIELDS = 'transientFields',
    CONTROLLER_ID = 'controlId',
    LAYOUT_TYPE = 'layoutType',
}

export function FieldArg(name: string): FieldArgument {
    return new FieldArgument(name);
}

export function StateArg(name: string): StateArgument {
    return new StateArgument(name);
}

export function ContextArg(arg: ContextArgEnum): ContextArgument {
    return new ContextArgument(arg);
}
