import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {DePermission} from '@synisys/idm-de-service-client-js';
import {FormData, FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {
    MetaField,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
/**
 * @author Vahagn Lazyan.
 * @since 2.5.2
 */
import {DynamicSubFormComponent} from '../../component';
import {MetaFormModel} from '../model/meta-form.model';
import {first, map} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';

export function getItemFromSubForm(subForm: DynamicSubFormComponent): Entity {
    if (subForm) {
        return subForm.item;
    }
}

export function loadFormMetaModel(
    kbData$: Observable<MetaField[]>,
    requiredMetaFields$: Observable<MetaFieldId[]>,
    languages$: Observable<Language[]>,
    formData$: Observable<FormData>,
    dePermission$?: Observable<DePermission>
): Observable<MetaFormModel> {
    dePermission$ = dePermission$ ? dePermission$ : of(undefined);
    return Observable.combineLatest(
        kbData$,
        requiredMetaFields$,
        formData$,
        languages$,
        dePermission$
    ).pipe(
        first(),
        map(
            (
                data: [
                    MetaField[],
                    MetaFieldId[],
                    FormData,
                    Language[],
                    DePermission
                ]
            ) => {
                const [
                    metaFields,
                    requiredFieldIds,
                    formData,
                    languages,
                    dePermission,
                ] = data;
                const wfStateMetaField: MetaField = metaFields.find(
                    (field: MetaField) => {
                        return field.getType() === MetaFieldType.WORKFLOW_STATE;
                    }
                );
                return new MetaFormModel(
                    formData.model,
                    requiredFieldIds,
                    metaFields,
                    languages,
                    wfStateMetaField,
                    formData.type,
                    formData.id,
                    dePermission
                );
            }
        )
    );
}
