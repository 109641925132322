import {DynamicArgument} from './dynamic-argument.type';
import {ControlModel} from '@synisys/idm-dynamic-layout-interpreter';
import {ContextEntity} from '../../component';

export class StateArgument implements DynamicArgument {
    private readonly value: string;
    constructor(arg: string) {
        this.value = arg;
    }

    public resolve(
        controlModel: ControlModel,
        contextEntity: ContextEntity | undefined
    ): string {
        if (
            controlModel.properties &&
            controlModel.properties.generalProperties
        ) {
            return controlModel.properties.generalProperties.get(
                this.value,
                'undefined'
            ) as string;
        } else {
            throw Error(`${controlModel.selector} has no properties`);
        }
    }
}
